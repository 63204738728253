<template>
  <MultiStepView done-label="Create Poll"
                 @done="create">
    <MultiStepPanel name="Configuration"
                    :completed="configurationComplete">
      <create-meeting-configuration v-model:attendees="attendees"
                                    v-model:beta-password="betaPassword"
                                    v-model:date-range="dateRange"
                                    v-model:deadline-active="deadlineActive"
                                    v-model:deadline="deadline"
                                    v-model:is-open="isOpenMeeting"
                                    v-model:organizer-name="organizerName"
                                    v-model:organizer-email="organizerEmail"
                                    v-model:reminder-active="reminderActive"
                                    v-model:reminder="reminder"
                                    v-model:subject="subject"
                                    v-model:advanced-options="advancedOptions"
                                    class="p-fluid ml-2 mr-2 md:ml-auto md:mr-auto text-left"
                                    style="max-width: 720px" />
    </MultiStepPanel>
    <MultiStepPanel name="Calendar">
      <ProposalCalendar ref="calendar"
                        v-model:entries="entries"
                        class="ml-1 md:ml-2 mr-1 md:mr-2"
                        :date-range="dateRange"
                        @import-success="$toast.add({severity: 'info', summary: 'Calendar imported!',
                                                     detail: 'The file(s) were imported successfully!', life: 3000})"
                        @import-fail="$toast.add({severity: 'error', summary: 'Unexpected error!',
                                                  detail: $event, life: 5000})" />
    </MultiStepPanel>
    <MultiStepPanel name="Overview"
                    :completed="readyToCreate">
      <CreateMeetingOverview :date-range="dateRange"
                             :subject="subject"
                             :reminder="deadlineActive && reminderActive && reminder || null"
                             :deadline="deadlineActive && deadline || null"
                             :attendees="attendees"
                             :open-meeting="isOpenMeeting"
                             :options="advancedOptions"
                             class="ml-2 mr-2 md:ml-auto md:mr-auto"
                             style="max-width: 720px" />
    </MultiStepPanel>
  </MultiStepView>

  <Dialog header="Creating meeting..."
          :close-on-escape="false"
          :closable="false"
          :modal="true"
          :visible="creatingMeeting">
    <div class="flex align-content-center">
      <ProgressSpinner />
    </div>
  </Dialog>
  <MeetingCreatedDialog v-model="resultDialogVisible"
                        :meeting-id="resultMeetingId"
                        :organizer-id="resultOrganizerId"
                        :attendees="resultAttendees"
                        :open-meeting="resultOpen"
                        :open-id="resultOpenId"
                        :open-key="resultOpenKey"
                        :options="advancedOptions" />
</template>

<script>
import ProposalCalendar from '../components/calendar/ProposalCalendar.vue'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import {createMeeting} from '@/api/openmeet-api'
import {addDays, inDateRange, setHours} from '@/components/calendar/calendar-helper'
import {parseISO8601Ranges} from '@/components/calendar/iso8601'
import CreateMeetingOverview from '../components/CreateMeetingOverview.vue'
import MeetingCreatedDialog from '../components/MeetingCreatedDialog.vue'
import CreateMeetingConfiguration from '../components/CreateMeetingConfiguration.vue'
import MultiStepPanel from "@/components/MultiStepPanel";
import MultiStepView from "@/components/MultiStepView";

export default {
  name: "CreateMeetingPage",
  components: {
    CreateMeetingConfiguration,
    CreateMeetingOverview,
    MultiStepView,
    MultiStepPanel,
    MeetingCreatedDialog,
    ProposalCalendar,
    Dialog,
    ProgressSpinner
  },
  provide() {
    return {getAttendee: () => ({})}
  },
  data: () => ({
    betaPassword: localStorage.getItem('betaPassword'),
    subject: '',
    entries: [],
    testDate: null,
    dateRange: undefined,
    deadlineActive: false,
    deadline: setHours(addDays(new Date(), 1), 17, 0),
    reminderActive: true,
    reminder: setHours(addDays(new Date(), 1), 14, 0),
    advancedOptions: {
      notifyAttendees: true,
      notifyOnNewProposal: true,
      notifyOnCompleteParticipation: true,
      closeAfterDeadline: true,
      anonymousEntries: false
    },
    organizerEmail: localStorage.getItem('user/email') || '',
    organizerName: localStorage.getItem('user/name') || '',
    attendees: [],
    isOpenMeeting: false,

    creatingMeeting: false,
    resultMeetingId: '',
    resultOrganizerId: '',
    resultAttendees: [],
    resultOpen: false,
    resultOpenId: '',
    resultOpenKey: '',
    resultDialogVisible: false
  }),
  computed: {
    configurationComplete() {
      if (this.organizerName === '' || this.organizerEmail.indexOf('@') === -1) return false
      if (this.subject === '' || this.betaPassword === '') return false
      if (this.dateRange === '') return false // In case date range is invalid for any reason (bug)
      return this.attendees.length > 0 || this.isOpenMeeting
    },
    readyToCreate() {
      return this.configurationComplete
    }
  },
  mounted() {
  },
  methods: {
    create() {
      // Update local storage
      try {
        localStorage.setItem('user/name', this.organizerName)
        localStorage.setItem('user/email', this.organizerEmail)
        localStorage.setItem('betaPassword', this.betaPassword)
      } catch (e) {
        console.error(e) /* ignore any errors */
      }
      this.creatingMeeting = true
      let dateRange = parseISO8601Ranges(this.dateRange)
      let filteredEntries = this.entries.filter(entry => inDateRange(entry.start, dateRange) || inDateRange(entry.end, dateRange))
      let calendarEntries = filteredEntries.filter(entry => entry.type !== 'proposal')
      let proposals = filteredEntries.filter(entry => entry.type === 'proposal')
      let options = {
        deadline: this.deadlineActive ? this.deadline : null,
        reminder: (this.deadlineActive && this.reminderActive) ? this.reminder : null,
        advanced: this.advancedOptions
      }
      createMeeting({
        organizer: this.organizerName + ' <' + this.organizerEmail + '>',
        attendees: this.attendees,
        subject: this.subject,
        dateRange: this.dateRange,
        calendarEntries, proposals, options, open: this.isOpenMeeting
      }, this.betaPassword)
          .then(result => {
            this.resultMeetingId = result.meetingId
            this.resultOrganizerId = result.organizerId
            let attendees = []
            for (let attendeeId in result.attendees) {
              if (!result.attendees[attendeeId]) continue
              let attendee = {...result.attendees[attendeeId], id: attendeeId, emailSent: false, emailFailed: false}
              if (result.sentEmails.includes(attendeeId))
                attendee.emailSent = true
              else if (result.failedEmails.includes(attendeeId))
                attendee.emailFailed = true
              attendees.push(attendee)
            }
            this.resultAttendees = attendees
            this.resultOpen = result.open
            this.resultOpenId = result.openId
            this.resultOpenKey = String(result.openKey)
            this.resultDialogVisible = true
          })
          .catch(result => {
            this.creatingMeeting = false
            let detail = result && result.response && result.response.status === 403 && 'Unauthorized!' || 'Something went wrong!'
            this.$toast.add({severity: 'error', summary: 'Failed to create meeting!', detail: detail, life: 5000})
          })
    }
  }
}
</script>

<style scoped>
tooltitext {
  max-width: 200px;
}
</style>