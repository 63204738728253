<template>
  <div class="calendar-details"
       :class="{'hidden': !visible}"
       :style="{'top': top + 'rem', 'left': left + 'px'}">
    <div v-for="(entry,index) in entries"
         :key="'details-block-'+index"
         class="grid ml-1 mr-1">
      <div class="col">
        <span>{{ formatTime(entry.start) }} - {{ formatTime(entry.end) }}</span>
        <p>{{ printUsers(entry) }}</p>
      </div>
      <Button v-if="!entry.readonly"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-text align-self-center"
              :disabled="disabled"
              @click="deleteEntry(entry)" />
    </div>
  </div>
</template>

<script>
import {toDateOnly} from '../calendar-helper'
import Style from '../scss/_export.module.scss'

const MS_PER_30MIN = 30 * 60 * 1000
const TIMESLOT_REM = parseFloat(Style.timeslotheight)

export default {
  name: "CalendarDetails",
  inject: ['formatTime', 'remToPx', 'getAttendee'],
  props: {disabled: {type: Boolean, default: () => false}},
  emits: ['delete'],
  data: () => ({
    left: 0,
    start: null,
    end: null,
    entries: [],
    visible: false
  }),
  computed: {
    top() {
      if (!this.start || !this.end) return 0
      let date = toDateOnly(this.start)
      let offset = ((this.start - date) + (this.end - date)) / 2
      return Math.max(0, (offset / MS_PER_30MIN) * TIMESLOT_REM)
    }
  },
  methods: {
    show(el, details) {
      this.left = el.$el.getBoundingClientRect().right - this.$parent.$el.getBoundingClientRect().left + this.remToPx(0.25)
      this.start = details.start
      this.end = details.end
      this.entries = details.entries
      this.visible = true
      document.addEventListener('mousedown', this.hide)
      document.addEventListener('touchstart', this.hide)
    },
    hide(evt) {
      if (evt && this.$el.contains(evt.target)) return
      document.removeEventListener('mousedown', this.hide)
      document.removeEventListener('touchstart', this.hide)
      this.visible = false
    },
    deleteEntry(entry) {
      this.$emit('delete', entry)
      for (let i = 0; i < this.entries.length; ++i) {
        if (entry !== this.entries[i]) continue
        this.entries.splice(i, 1)
        break
      }
      if (this.entries.length === 0) this.hide()
    },
    printUsers(entry) {
      if (entry.summary) return entry.summary
      if (!entry.readonly) return 'No description.'
      if (entry.user != null) {
        let attendee = this.getAttendee(entry.user)
        if (attendee && attendee.name) return attendee.name
      }
      if (!entry.users) return "Someone's busy at that time"
      return entry.users.map(id => this.getAttendee(id).name).join(', ')
    }
  }
}
</script>

<style lang="scss">
@import "../scss/variables";

.calendar-details {
  position: absolute;
  min-width: 16rem;
  max-width: calc(100% - 10rem);
  background: white;
  border: 1px solid $blocked-color;
  border-radius: $border-radius;
  transform: translateY(-50%);
  padding: 0.5rem;
  text-align: left;
  z-index: 10;

  span { font-weight: bold; }

  p { margin: 0; }
}

.calendar-details:after {
  content: "";
  display: block;
  position: absolute;
  border-color: transparent $blocked-color transparent;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-style: solid;
  left: -0.4rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.hidden {
  display: none;
}
</style>