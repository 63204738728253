import {ref} from 'vue'

export default class ReactiveSize {
  constructor(element) {
    this.element = element
    this.observer = new ResizeObserver(this.onResize.bind(this))
    this.observer.observe(this.element)
    this.width = ref(0)
    this.height = ref(0)
    this.onResize()
  }

  onResize() {
    this.width.value = this.element.clientWidth
    this.height.value = this.element.clientHeight
  }
}
