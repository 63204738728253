import {toDateOnly} from '../calendar-helper'

export function groupEntries(input) {
  if (!input || !(input.length > 0)) return []
  const MS_PER_15MIN = 15 * 60 * 1000
  const START = 0
  const END = 1
  let currentDay = toDateOnly(input[0].start)
  // Round entries to 15 min slots and map them to separate time objects for start and end to make grouping easier
  // O(n) instead of having to do complicated backtracking
  let times = input.map(entry => {
    let start = Math.floor((entry.start - currentDay + 5000) / MS_PER_15MIN) * MS_PER_15MIN
    let startDate = new Date(currentDay)
    startDate.setMilliseconds(startDate.getMilliseconds() + start)
    let end = Math.ceil((entry.end - currentDay - 5000) / MS_PER_15MIN) * MS_PER_15MIN
    let endDate = new Date(currentDay)
    endDate.setMilliseconds(endDate.getMilliseconds() + end)
    return [{time: startDate, type: START, entry: entry}, {time: endDate, type: END, entry: entry}]
  }).flat()
  times.sort((a, b) => a.time - b.time)

  // Group overlapping entries
  let result = []
  let current = {start: times[0].time, end: null, entries: []}
  let entries = []
  for (let item of times) {
    if (Math.abs(item.time - current.start) > 5000) {
      current.end = item.time
      current.entries = Array.from(entries)
      if (current.entries.length !== 0) result.push(current)
      current = {start: item.time, end: null, entries: []}
    }
    if (item.type === START) {
      entries.push(item.entry)
    } else {
      let index = entries.indexOf(item.entry)
      if (index >= 0) entries.splice(index, 1)
    }
  }
  return result
}
