<!-- Based on PrimeVue SelectButton with added option to clear selection -->
<template>
  <div class="p-selectbutton p-buttonset p-component"
       role="group">
    <div v-for="(option, i) of options"
         :key="getOptionRenderKey(option)"
         v-ripple
         :aria-label="getOptionLabel(option)"
         role="button"
         :aria-pressed="isSelected(option)"
         :tabindex="isOptionDisabled(option) ? null : '0'"
         :aria-labelledby="ariaLabelledBy"
         :class="getButtonClass(option)"
         @click="onOptionSelect($event, option, i)"
         @keydown.enter.prevent="onOptionSelect($event, option, i)"
         @keydown.space.prevent="onOptionSelect($event, option)"
         @focus="onFocus($event)"
         @blur="onBlur($event)">
      <slot name="option"
            :option="option"
            :index="i">
        <span class="p-button-label">{{ getOptionLabel(option) }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
import {ObjectUtils} from 'primevue/utils';
import Ripple from 'primevue/ripple';
export default {
  name: 'SelectButton',
  directives: {
    'ripple': Ripple
  },
  props: {
    modelValue: {type: String, default: () => null},
    options: {type: Array, default: () => []},
    optionLabel: {type: Object, default: () => null},
    optionValue: {type: Object, default: () => null},
    optionDisabled: {type: Object, default: () => null},
    multiple: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    dataKey: {type: Object, default: () => null},
    ariaLabelledBy: {type: Object, default: () => null}
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  computed: {
    equalityKey() {
      return this.optionValue ? null : this.dataKey;
    }
  },
  methods: {
    getOptionLabel(option) {
      return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option;
    },
    getOptionValue(option) {
      return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : option;
    },
    getOptionRenderKey(option) {
      return this.dataKey ? ObjectUtils.resolveFieldData(option, this.dataKey) : this.getOptionLabel(option);
    },
    isOptionDisabled(option) {
      return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : false;
    },
    onOptionSelect(event, option) {
      if (this.disabled || this.isOptionDisabled(option)) {
        return;
      }
      let selected = this.isSelected(option);
      let optionValue = this.getOptionValue(option);
      let newValue;
      if(this.multiple) {
        if (selected)
          newValue = this.modelValue.filter(val => !ObjectUtils.equals(val, optionValue, this.equalityKey));
        else
          newValue = this.modelValue ? [...this.modelValue, optionValue]: [optionValue];
      }
      else {
        newValue = (this.clearable && selected) ? null : optionValue;
      }
      this.$emit('update:modelValue', newValue);
    },
    isSelected(option) {
      let selected = false;
      let optionValue = this.getOptionValue(option);
      if (this.multiple) {
        if (this.modelValue) {
          for (let val of this.modelValue) {
            if (ObjectUtils.equals(val, optionValue, this.equalityKey)) {
              selected = true;
              break;
            }
          }
        }
      }
      else {
        selected = ObjectUtils.equals(this.modelValue, optionValue, this.equalityKey);
      }
      return selected;
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    getButtonClass(option) {
      return ['p-button p-component', {
        'highlight': this.isSelected(option),
        'disabled': this.isOptionDisabled(option)
      }];
    }
  }
}
</script>