<template>
  <div>
    <!-- Controls -->
    <div class="flex mb-0 md:mb-2">
      <!--      <div class="col-fixed">-->
      <!--        <Button label="Quick Add"-->
      <!--                @click="quickAddDialog = true" />-->
      <!--      </div>-->
      <div class="col" />
      <div v-if="showImport"
           class="col-fixed">
        <Button label="Import"
                icon="pi pi-calendar"
                icon-pos="right"
                @click="importDialog = true" />
      </div>
    </div>
    <!-- Calendar -->
    <div ref="calendarWrapper"
         class="calendar-main"
         :class="{'proposal-active': addType === 'proposal',
                  'blocked-active': addType === 'blocked'}"
         @dragover="onDragOver"
         @dragend="onDragLeave"
         @drop="onDrop"
         @dragleave="onDragLeave">
      <div class="calendar-headers">
        <div class="progress-border calendar-previous"
             :style="{'--answered-proposals': (1 - unansweredProposalsBeforeFirstVisibleDate / proposalsBeforeFirstVisibleDate)}">
          <Button class="p-button-rounded p-button-plain"
                  icon="pi pi-arrow-left"
                  :label="proposalsBeforeFirstVisibleDate"
                  :disabled="!hasPreviousDay"
                  @click="showPreviousDates" />
        </div>
        <template v-for="date in visibleDates"
                  :key="'date-header-'+date">
          <div class="day-header">
            <div>
              {{ date.toLocaleDateString([], {weekday: 'long'}) }}<br>
              <small>
                CW {{ getWeekNumber(date).toString().padStart(2, '0') }}: {{
                  date.toLocaleDateString(['de', 'en-GB'], {
                    month: '2-digit',
                    day: '2-digit',
                    year: displayYear ? '2-digit' : undefined
                  })
                }}
              </small>
            </div>
            <div class="day-proposal-count"
                 :class="{'hidden': getProposalCountForDate(date) === 0,
                          'done': getAnsweredProposalCountForDate(date) === getProposalCountForDate(date)}"
                 :style="{'--answered-proposals': getAnsweredProposalCountForDate(date) / getProposalCountForDate(date)}">
              <span>
                {{ getProposalCountForDate(date) }}
              </span>
            </div>
          </div>
        </template>
        <div class="progress-border calendar-next"
             :style="{'--answered-proposals': (1 - unansweredProposalsAfterLastVisibleDate / proposalsAfterLastVisibleDate)}">
          <Button class="p-button-rounded p-button-plain"
                  icon="pi pi-arrow-right"
                  :label="proposalsAfterLastVisibleDate"
                  :disabled="!hasNextDay"
                  @click="showNextDates" />
        </div>
      </div>
      <div ref="content"
           class="calendar-content"
           @scroll="scroll = $refs.content.scrollTop">
        <template v-for="date in visibleDates"
                  :key="'date-header-'+date">
          <CalendarDay :date="date"
                       :entries="sortedEntries"
                       :create-entry-template="combinedCreateEntryTemplate"
                       :disabled="disabled"
                       @update:entries="$emit('update:entries', $event)" />
        </template>
      </div>
      <div class="proposal-indicators">
        <template v-for="(indicators, index) in proposalIndicators"
                  :key="'proposal-indicator-'+index">
          <div class="proposal-indicator indicator-top"
               :class="{'p-hidden-space': indicators.top.total === 0}"
               :style="{'--answered-proposals': indicators.top.total === 0 ? 0 : indicators.top.answered / indicators.top.total}">
            {{ indicators.top.total }}
          </div>
          <div class="proposal-indicator indicator-bottom"
               :class="{'p-hidden-space': indicators.bottom.total === 0}"
               :style="{'--answered-proposals': indicators.bottom.total === 0 ? 0 : indicators.top.answered / indicators.top.total}">
            {{ indicators.bottom.total }}
          </div>
        </template>
      </div>
      <div id="drop-view"
           :class="{'accept-drop': dragState === 'accept', 'reject-drop': dragState === 'reject'}">
        <span class="accept-text">Drop calendar files here</span>
        <span class="reject-text">Only .ics is supported right now</span>
      </div>
    </div>
    <QuickAddDialog v-model:visible="quickAddDialog" />
    <WebCalendarImportDialog v-model="importDialog" :calendar="this" :providers="importProviders" />
  </div>
</template>

<script>
import Button from 'primevue/button'
import Style from './scss/_export.module.scss'
import {addDays, getWeekNumber, inDateRange, isSameDay, toDateOnly} from './calendar-helper'
import {parseISO8601Ranges} from './iso8601'
import CalendarDay from './internal/CalendarDay'
import ReactiveSize from './internal/reactive-size'
import QuickAddDialog from './QuickAddDialog'
import WebCalendarImportDialog from './WebCalendarImportDialog'
import GoogleCalendarProvider from './providers/google'
import ICalProvider from './providers/ical-provider'

function remToPx(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

function pxToRem(px) {
  return px / parseFloat(getComputedStyle(document.documentElement).fontSize)
}

if (!Style.timeslotheight) {
  console.error("Could not import scss variables in javascript make sure the project is set up correctly!", style)
}

const DEFAULT_SCROLL = remToPx(parseFloat(Style.timeslotheight) * 2 * 7);
const MIN_DAY_WIDTH = remToPx(30)
const PX_PER_30MIN = remToPx(parseFloat(Style.timeslotheight))

const dateFormatter = new Intl.DateTimeFormat([], {dateStyle: 'short'})
const timeFormatter = new Intl.DateTimeFormat([], {timeStyle: 'short', hour12: false})

let newEntryId = -1

export default {
  name: "ProposalCalendar",
  components: {WebCalendarImportDialog, QuickAddDialog, CalendarDay, Button},
  provide() {
    return {
      formatDate: date => dateFormatter.format(date),
      formatTime: date => timeFormatter.format(date),
      remToPx: remToPx,
      pxToRem: pxToRem,
      userId: this.userId
    }
  },
  props: {
    //! An array of objects with {start: JSDate, end: JSDate, type: String ("blocked", "proposal")}
    entries: {
      type: Array,
      default: () => []
    },
    //! Can contain dates in ISO format: '2021-06-02', date ranges '2021-06-02/2021-06-05' and combinations separated by comma
    dateRange: {type: String, default: ''},
    disabledDays: {
      type: Array,
      default: () => []
    },
    createEntryTemplate: {
      type: Function,
      default: () => ({})
    },
    userId: {type: Number, default: 0},
    showImport: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    importProviders: {type: Array, default: () => [new GoogleCalendarProvider(), new ICalProvider()]}
  },
  emits: ['update:entries', 'import', 'import-success', 'import-fail'],
  data: () => ({
    addType: 'none',
    dragState: 'none',
    currentIndex: 0,
    creatingTimeslot: false,
    creatingTimeslotStartX: 0,
    creatingTimeslotDateOffset: 0,
    scroll: null,
    contentSize: null,
    importDialog: false,
    quickAddDialog: false,
  }),
  computed: {
    sortedEntries() {
      let entries = this.entries || []
      entries.sort((a, b) => a.start - b.start)
      return entries
    },
    dates() {
      let result = []
      let ranges = this.dateRange && parseISO8601Ranges(this.dateRange) || []
      for (let range of ranges) {
        for (let date = new Date(range.start); date <= range.end; date.setDate(date.getDate() + 1)) {
          result.push(new Date(date))
        }
      }
      result.sort((a, b) => a < b ? -1 : 1)
      return result
    },
    displayYear() {
      if (this.dates[0].getYear() !== new Date().getFullYear()) return true
      return this.dates[this.dates.length - 1].getYear() !== new Date().getFullYear()

    },
    numberOfVisibleDates() {
      if (this.contentSize === null) return 1
      return Math.max(1, Math.floor(this.contentSize.width / MIN_DAY_WIDTH))
    },
    visibleDates() {
      let index = Math.min(this.currentIndex, this.dates.length - this.numberOfVisibleDates)
      return this.dates.slice(index, index + this.numberOfVisibleDates)
    },
    proposals() {
      let result = []
      for (let entry of this.sortedEntries) {
        if (entry.type !== 'proposal') continue
        result.push(entry)
      }
      return result
    },
    unansweredProposals() {
      let result = []
      for (let entry of this.proposals) {
        if (entry.votes) {
          // Check if user selected an option
          let answered = this.isAnswered(entry)
          if (answered) continue
        }
        result.push(entry)
      }
      return result
    },
    proposalsBeforeFirstVisibleDate() {
      if (this.visibleDates.length === 0 || this.proposals.length === 0) return ''
      for (let count = 0; count < this.proposals.length; ++count) {
        if (this.proposals[count].end > this.visibleDates[0])
          return count > 0 ? count.toString() : ''
      }
      return this.proposals.length.toString()
    },
    unansweredProposalsBeforeFirstVisibleDate() {
      if (this.visibleDates.length === 0 || this.unansweredProposals.length === 0) return ''
      for (let count = 0; count < this.unansweredProposals.length; ++count) {
        if (this.unansweredProposals[count].end > this.visibleDates[0])
          return count > 0 ? count.toString() : ''
      }
      return this.unansweredProposals.length.toString()
    },
    proposalsAfterLastVisibleDate() {
      if (this.visibleDates.length === 0 || this.proposals.length === 0) return ''
      let lastDate = addDays(this.visibleDates[this.visibleDates.length - 1], 1)
      for (let count = 0; count < this.proposals.length; ++count) {
        if (this.proposals[this.proposals.length - 1 - count].start < lastDate)
          return count > 0 ? count.toString() : ''
      }
      return this.proposals.length.toString()
    },
    unansweredProposalsAfterLastVisibleDate() {
      if (this.visibleDates.length === 0 || this.unansweredProposals.length === 0) return ''
      let lastDate = addDays(this.visibleDates[this.visibleDates.length - 1], 1)
      for (let count = 0; count < this.unansweredProposals.length; ++count) {
        if (this.unansweredProposals[this.unansweredProposals.length - 1 - count].start < lastDate)
          return count > 0 ? count.toString() : ''
      }
      return this.unansweredProposals.length.toString()
    },
    firstVisibleTimeInMinutes() {
      return this.scroll * 30 / PX_PER_30MIN
    },
    lastVisibleTimeInMinutes() {
      if (this.contentSize == null) return 24 * 60
      return (this.scroll + this.contentSize.height) * 30 / PX_PER_30MIN
    },
    proposalIndicators() {
      let result = []
      let index = 0
      // eslint-disable-next-line no-unused-vars
      for (let date of this.visibleDates) {
        let nextDay = addDays(date, 1)
        let start = toDateOnly(date)
        start.setMinutes(start.getMinutes() + this.firstVisibleTimeInMinutes)
        let end = new Date(date)
        end.setMinutes(end.getMinutes() + this.lastVisibleTimeInMinutes)
        let top = 0, topAnswered = 0, bottom = 0, bottomAnswered = 0
        for (; index < this.sortedEntries.length; ++index) {
          let entry = this.sortedEntries[index]
          if (entry.type !== 'proposal') continue
          if (entry.end <= date) continue
          if (entry.start >= nextDay) break
          if (entry.end <= start) {
            ++top
            if (this.isAnswered(entry)) ++topAnswered
          }
          if (entry.start >= end) {
            ++bottom
            if (this.isAnswered(entry)) ++bottomAnswered
          }
        }
        result.push({top: {answered: topAnswered, total: top}, bottom: {answered: bottomAnswered, total: bottom}})
      }
      return result
    },
    hasPreviousDay() {
      return this.currentIndex > 0
    },
    hasNextDay() {
      return this.currentIndex < this.dates.length - this.numberOfVisibleDates
    }
  },
  mounted() {
    this.$refs.content.scrollTo(0, DEFAULT_SCROLL)
    this.contentSize = new ReactiveSize(this.$refs.content)
  },
  methods: {
    combinedCreateEntryTemplate(type) {
      return {id: newEntryId--, ...this.createEntryTemplate(type)}
    },
    getWeekNumber: getWeekNumber,
    getBadgeClass(unanswered, total) {
      if (!unanswered) return 'done'
      return unanswered === total ? 'none-answered' : ''
    },
    isAnswered(entry) {
      for (let key in entry.votes) {
        if (!entry.votes[key] || !entry.votes[key].selected) continue
        return true
      }
      return false
    },
    getProposalCountForDate(date) {
      let count = 0
      for (let entry of this.sortedEntries) {
        if (entry.type !== 'proposal') continue
        if (!(isSameDay(date, entry.start) || isSameDay(date, entry.end))) continue
        ++count
      }
      return count
    },
    getAnsweredProposalCountForDate(date) {
      let count = 0
      for (let entry of this.sortedEntries) {
        if (entry.type !== 'proposal') continue
        if (!(isSameDay(date, entry.start) || isSameDay(date, entry.end))) continue
        if (!this.isAnswered(entry)) continue
        ++count
      }
      return count
    },
    showPreviousDates() {
      let index = Math.max(this.currentIndex - this.numberOfVisibleDates, 0)
      if (this.currentIndex === index) return
      this.currentIndex = index
    },
    showNextDates() {
      let index = Math.min(this.currentIndex + this.numberOfVisibleDates, this.dates.length - this.numberOfVisibleDates)
      if (this.currentIndex === index) return
      this.currentIndex = index
    },
    importEntries(calEntries) {
      let entries = this.entries
      for (let entry of calEntries) {
        entries.push({
          summary: entry.summary || '',
          start: entry.start,
          end: entry.end,
          type: 'blocked'
        })
      }
      entries.sort((a, b) => a.start - b.start)
      // Filter duplicates
      for (let i = entries.length - 2; i >= 0; --i) {
        if (new Date(entries[i].start).getTime() !== new Date(entries[i + 1].start).getTime()) continue
        if (new Date(entries[i].end).getTime() !== new Date(entries[i + 1].end).getTime()) continue
        if (entries[i].type !== entries[i + 1].type) continue
        // Remove duplicate
        entries.splice(i + 1, 1)
      }
      this.$emit('import-success')
      this.$emit('update:entries', entries)
    },
    onDragOver(evt) {
      if (!evt.dataTransfer || !evt.dataTransfer.items) return
      for (let item of evt.dataTransfer.items) {
        if (item.type !== "text/calendar") continue
        this.dragState = 'accept'
        evt.preventDefault()
        return
      }
      this.dragState = 'reject'
      evt.preventDefault()
    },
    onDragLeave() {
      this.dragState = 'none'
    },
    onDrop(evt) {
      evt.preventDefault()
      if (this.dragState === 'accept' && evt.dataTransfer) this.processFiles(evt.dataTransfer.files)
      this.dragState = 'none'
    }
  }
}
</script>

<style lang="scss">
@use "sass:color";
@import "scss/variables";

$calendarHeaderHeight: 3rem;

.calendar-main {
  position: relative;

  .calendar-headers {
    display: flex;
    align-items: center;
    background: var(--primary-color);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    height: $calendarHeaderHeight;

    .day-header {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: center;
      margin-left: 2rem;
      color: var(--primary-color-text);
      padding: .5rem;
      font-weight: bold;

      .day-proposal-count {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-self: center;
        margin-left: 0.5rem;
        background: conic-gradient($accept-color calc(var(--answered-proposals) * 100%), color.adjust($proposal-color, $lightness: 15%) 0);
        line-height: 2rem;
        border-radius: .4rem;

        span {
          display: block;
          flex-grow: 1;
          background: var(--primary-color);
          border-radius: .36rem;
          margin: 0.2rem;
          line-height: 1.8rem;
        }

        &.done {
          span {
            background: $accept-color;
          }
        }
      }
    }

    .calendar-previous, .calendar-next {
      position: absolute;
      margin-left: 0.25rem;
      background: red;
      border: none;
      background: radial-gradient(closest-side, var(--primary-color) 80%, transparent 0), conic-gradient($accept-color calc(var(--answered-proposals) * 100%), #eee 0);
      padding: 0.2rem;
      border-radius: 2rem;

      .p-button-label {
        margin-top: 0.1rem;
        margin-bottom: -0.1rem;
        font-weight: bold;
      }

      &:focus-within {
        box-shadow: 0 0 0 0.2rem #a6d5fa;
      }

      .p-button:focus {
        box-shadow: none;
      }
    }

    .calendar-next {
      right: 0;
      margin-left: 0;
      margin-right: 0.25rem;
    }
  }

  .calendar-content {
    display: flex;
    align-items: flex-start;
    min-height: #{10 * $time-slot-height}; // at least enough space for 5 hours
    height: calc(100vh - 16rem);
    overflow: auto;
  }
}

#drop-view {
  display: none;
  position: absolute;
  left: 0;
  top: $calendarHeaderHeight;
  right: 0;
  bottom: 0;
  background: #cccccc88;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  span {
    font-size: 2rem;
    font-weight: bold;
  }

  .accept-text, .reject-text {
    display: none;
  }
}

#drop-view.accept-drop {
  display: flex;

  .accept-text {
    display: block;
  }
}

#drop-view.reject-drop {
  display: flex;

  .reject-text {
    display: block;
  }
}

#addModeSelect .p-button.highlight[aria-label='Block'] {
  background: $blocked-color;
}

#addModeSelect .p-button.highlight[aria-label='Propose'] {
  background: $proposal-color;
}

.fill-height {
  height: 100%;
}

.proposal-indicators {
  position: absolute;
  left: 0;
  right: 0;
  top: 3rem;
  bottom: 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  justify-items: center;
  pointer-events: none;
}

.proposal-indicator {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 11;
  background: $proposal-indicator-color;
  //background: radial-gradient(closest-side, var($proposal-indicator-color) 80%, transparent 0), conic-gradient($accept-color calc(var(--answered-proposals) * 100%), #eee 0);
  border-radius: .3rem;
  color: $proposal-text-color;
  line-height: 1.5rem;
  font-size: 1rem;
  margin: 0.6rem;
}

.proposal-indicator:after, .proposal-indicator:after {
  border-color: $proposal-indicator-color transparent;
}

.indicator-top {
  align-self: flex-start;
}

.indicator-bottom {
  align-self: flex-end;
}

.indicator-top:after, .indicator-bottom:after {
  content: "";
  border-style: solid;
  position: relative;
  z-index: 2;
  display: block;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -0.4rem;
}

.indicator-top:after {
  border-width: 0 .4rem 0.4rem 0.4rem;
  top: -1.85rem;
}

.indicator-bottom:after {
  border-width: .4rem 0.4rem 0 0.4rem;
  top: 0;
}

</style>
