<template>
  <Dialog v-bind="$attrs"
          v-model:visible="visible"
          :closable="!importing"
          :close-on-escape="!importing"
          header="Import Calendar"
          :breakpoints="{'640px': '100vw'}">
    <div v-if="loading || importing" class="flex align-content-center">
      <ProgressSpinner />
      <template v-if="importing">
        <span class="font-bold">
          Processing calendar {{ importProgress.calendar }} of {{ importProgress.calendars }}...
        </span><br>
        <span v-if="importProgress.entry !== 0">
          {{ importProgress.entry }} entries imported
          <template v-if="importProgress.entries"> of {{ importProgress.entries }}</template>
        </span>
      </template>
    </div>
    <template v-else>
      <table class="calendar-import-table">
        <tr>
          <td>
            <Checkbox v-model="allCalendarsSelected"
                      :binary="true" />
          </td>
          <td colspan="2">
            <span style="font-weight:bold;margin-left:0.5rem">Select all</span>
          </td>
        </tr>
        <tr v-for="(cal, i) in calendars"
            :key="'calendar-'+i">
          <td>
            <Checkbox v-model="selectedCalendars[i]"
                      :binary="true" />
          </td>
          <td>
            <span class="calendar-color"
                  :style="{'background': cal.color || '#fff'}" />
          </td>
          <td>{{ cal.name }}</td>
        </tr>
      </table>
      <div class="mt-4">
        <small>Event titles are for convenience and will be removed before the data is sent to the server.</small>
      </div>
    </template>
    <template v-if="!loading && !importing" #footer>
      <Button label="Cancel"
              @click="importDialog=false" />
      <Button label="Import"
              @click="importCalendars" />
    </template>
  </Dialog>
</template>

<script>
import Button from "primevue/button"
import Checkbox from "primevue/checkbox"
import Dialog from 'primevue/dialog'
import ProgressSpinner from "primevue/progressspinner"
import {addDays} from "./calendar-helper"

export default {
  name: "ImportCalendarDialog",
  components: {Button, Checkbox, Dialog, ProgressSpinner},
  props: {
    calendar: {type: Object, default: () => null},
    calendarProvider: {type: Object, default: () => null},
    modelValue: {type: Boolean, default: () => false}
  },
  emits: ['update:modelValue', 'load', 'importComplete'],
  data: () => ({
    loading: true,
    calendars: [],
    importing: false,
    importProgress: {calendar: 0, calendars: 0, entry: 0, entries: 0},
    selectedCalendars: []
  }),
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    allCalendarsSelected: {
      get() {
        return this.selectedCalendars.every(x => x)
      },
      set(val) {
        this.selectedCalendars = this.calendars.map(() => val)
      }
    }
  },
  watch: {
    async calendarProvider() {
      this.loading = true
      this.calendars = await this.calendarProvider.getCalendars()
      this.selectedCalendars = this.calendars.map(cal => !!cal.primary)
      this.loading = false
      this.$emit('load')
    }
  },
  methods: {
    async importAll() {
      this.selectedCalendars = this.calendars.map(() => true)
      await this.importCalendars()
    },
    async importCalendars() {
      this.importProgress.calendars = this.selectedCalendars.filter(Boolean).length
      this.importProgress.calendar = 0
      this.importProgress.entry = 0
      this.importing = true
      this.importDialog = false
      let from = this.calendar.dates[0]
      let to = addDays(this.calendar.dates[this.calendar.dates.length - 1], 1)
      for (let i = 0; i < this.calendars.length; ++i) {
        if (!this.selectedCalendars[i]) continue
        ++this.importProgress.calendar
        this.importProgress.entry = 0
        let token = undefined
        let result = null
        do {
          result = await this.calendars[i].getEvents(from, to)
          this.calendar.importEntries(result.events)
          this.importProgress.entry += result.events.length
        } while (!!(token = result.nextToken));
      }
      this.importing = false
      this.$emit('update:modelValue', false)
      this.$emit('importComplete')
    }
  }
}
</script>

<style scoped>

</style>