export default class TouchHandler {
  constructor() {
    this.active = false
    this.touchId = -1
    this.onmove = null
    this.oncancel = null
    this.onend = null

    this._movehandler = this.move.bind(this)
    this._cancelhandler = this.cancel.bind(this)
    this._endhandler = this.end.bind(this)
  }

  start(evt) {
    if (evt.type === 'touchstart') {
      this.touchId = evt.changedTouches.item(0).identifier
      document.addEventListener('touchmove', this._movehandler, {capture: true})
      document.addEventListener('touchcancel', this._cancelhandler, {capture: true})
      document.addEventListener('touchend', this._endhandler, {capture: true})
    } else {
      this.touchId = -1
      document.addEventListener('mousemove', this._movehandler, {capture: true})
      document.addEventListener('mouseup', this._endhandler, {capture: true})
    }
    this.active = true
  }

  move(evt) {
    if (!this.active) return
    if (this.touchId !== -1) {
      let touch = this._getTrackedTouch(evt.touches)
      if (!touch) {
        this.cancel(null)
        return
      }
      evt = touch
    }
    if (this.onmove) this.onmove(evt)
  }

  cancel(evt) {
    if (!this.active) return
    if (this.touchId !== -1 && evt) {
      let touch = this._getTrackedTouch(evt.changedTouches)
      if (!touch) return
      evt = touch
    }
    this._removeListeners()
    this.active = false
    if (this.oncancel) this.oncancel(evt)
  }

  end(evt) {
    if (!this.active) return
    if (this.touchId !== -1) {
      let touch = this._getTrackedTouch(evt.changedTouches)
      if (!touch) return
      evt = touch
    }
    this._removeListeners()
    this.active = false
    if (this.onend) this.onend(evt)
  }

  _getTrackedTouch(touches) {
    if (!touches) return null
    for (let i = 0; i < touches.length; ++i) {
      if (touches.item(i).identifier === this.touchId) return touches.item(i)
    }
    return null
  }

  _removeListeners() {
    if (this.touchId !== -1) {
      document.removeEventListener('touchmove', this._movehandler, {capture: true})
      document.removeEventListener('touchcancel', this._cancelhandler, {capture: true})
      document.removeEventListener('touchend', this._endhandler, {capture: true})
    } else {
      document.removeEventListener('mousemove', this._movehandler, {capture: true})
      document.removeEventListener('mouseup', this._endhandler, {capture: true})
    }
  }
}
