<template>
  <div class="icon-text">
    <div class="icon-text-icon col-fixed">
      <i class="pi mr-2"
         :class="icon"
         :style="!!color ? 'color:'+color : ''" />
    </div>
    <div class="col">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "IconText",
  props: {icon: {type: String, default: ''}, color: {type: String, default: ''}}
}
</script>

<style lang="scss">
.icon-text {
  display: flex;
  align-items: center;
}
</style>