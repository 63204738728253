<template>
  <Dialog v-bind="$attrs" v-model:visible="visible"
          header="Import Calendar"
          :breakpoints="{'640px': '100vw'}">
    Import events from your calendar to mark them as blocked.<br>
    <small>
      All information about the event except for start and end times will be stripped before the data is sent to the
      server.
    </small>

    <div v-if="!loading" class="flex flex-wrap mt-2 mb-2">
      <Button v-for="provider in providers" :key="provider.id"
              class="m-2" icon-pos="left" :icon="provider.icon && 'pi ' + provider.icon" :label="provider.displayName"
              @click="importFrom(provider)" />
    </div>
    <div v-if="loading" class="flex mt-4 mb-4 align-items-center justify-content-center">
      <ProgressSpinner class="m-2" style="width:2rem;height:2rem" />
      <div class="mt-1 ml-1">
        <span class="font-bold">{{ status }}</span>
      </div>
    </div>

    <small>To protect your privacy third-party scripts are only loaded when you click on the respective calendar
      service.</small>

    <ImportCalendarDialog ref="importDialog" v-model="showImportDialog"
                          :calendar="calendar" :calendar-provider="calendarProvider"
                          @load="onCalendarsLoaded" @import-complete="visible = false" />
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import GoogleCalendarProvider from './providers/google'
import ICalProvider from './providers/ical-provider'
import ImportCalendarDialog from './ImportCalendarDialog'

export default {
  name: "WebCalendarImportDialog",
  components: {ImportCalendarDialog, Dialog, ProgressSpinner},
  props: {
    calendar: {type: Object, default: () => null},
    modelValue: {type: Boolean, default: () => false},
    providers: {type: Array, default: () => [new GoogleCalendarProvider(), new ICalProvider()]}
  },
  emits: ['update:modelValue', 'importFiles'],
  data: () => ({
    loading: false,
    status: '',
    calendarProvider: null,
    showImportDialog: false
  }),
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    async importFrom(provider) {
      this.loading = true
      try {
        this.status = "Importing from " + provider.displayName
        await provider.init()
        this.calendarProvider = provider
        this.showImportDialog = true
      } catch (e) {
        // TODO
      }
      this.loading = false
    },
    onCalendarsLoaded() {
      if (this.calendarProvider.autoImportAll !== true) return
      this.$refs.importDialog.importAll()
    }
  }
}
</script>

<style scoped>

</style>