<template>
  <div id="appbar">
    <a class="logo"
       href="/">
      <img src="/logo.png">
    </a>
    <RouterLink class="navigation"
                active-class="navigation-active"
                to="/create-meeting">
      Schedule a Meeting
    </RouterLink>
  </div>
  <router-view />
  <Toast position="top-right"
         :base-z-index="1000" />
</template>

<script>
import {defineComponent} from 'vue'
import Toast from 'primevue/toast'
import {setConfig} from './api/openmeet-api'

export default defineComponent({
  name: 'App',
  components: {Toast},
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      console.log("Using dev api")
      setConfig({
        domain: 'http://localhost:8080',
        api: 'https://jziduugyuh.execute-api.eu-west-1.amazonaws.com/dev'
      })
    }
  }
})
</script>

<style lang="scss">
$app-bar-height: 2.5rem;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#appbar {
  display: flex;
  position: sticky;
  align-items: flex-end;
  left: 0;
  top: 0;
  z-index: 999;
  background: #0d89ec;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  height: $app-bar-height;

  a.logo {
    align-self: stretch;
    text-decoration: none;
    color: #fff;
    width: 8rem;
  }

  img {
    height: 100%;
  }

  .navigation {
    text-decoration: none;
    color: #fff;
    height: 100%;
    line-height: #{$app-bar-height + 0.25rem};
    padding: 0 1rem;
  }

  .navigation:hover:not(.navigation-active) {
    background: #ffffff44;
  }

  .navigation-active {
    background: #ffffff;
    color: var(--primary-color);
  }
}

body {
  margin: 0
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.fill-height {
  height: 100%;
}

.info, .chip.info {
  background: #0288D1;
  color: #fff;
}

.warning {
  background: #FBC02D;
  color: #212529;
}

.danger {
  background: #D32F2F;
  color: #fff;
}

</style>