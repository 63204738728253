<template>
  <div>
    <div class="p-fluid formgrid grid text-left">
      <!-- Organizer -->
      <div class="col-12 field">
        <span class="p-float-label">
          <InputText id="organizerName"
                     type="text"
                     :model-value="organizerName"
                     @update:model-value="$emit('update:organizerName', $event)" />
          <label for="organizerName">Organizer Name</label>
        </span>
      </div>
      <div class="col-12 field mt-3">
        <span class="p-float-label">
          <InputText id="organizerEmail"
                     type="email"
                     :model-value="organizerEmail"
                     aria-describedby="organizerEmail-help"
                     @update:model-value="$emit('update:organizerEmail', $event)" />
          <label for="organizerEmail">Organizer E-Mail</label>
        </span>
        <small id="organizerEmail-help">Required to send you a management link and updates</small>
      </div>
      <!-- Subject -->
      <div class="col-12 field mt-3">
        <span class="p-float-label">
          <InputText id="subject"
                     type="text"
                     :model-value="subject"
                     @update:model-value="$emit('update:subject', $event)" />
          <label for="subject">Subject</label>
        </span>
        <small>What this meeting is about.</small>
      </div>
      <div class="col-12 field mt-3">
        <span class="p-float-label">
          <InputText id="betaPassword"
                     type="text"
                     :model-value="betaPassword"
                     @update:model-value="$emit('update:betaPassword', $event)" />
          <label for="betaPassword">Beta Password</label>
        </span>
        <small>This service is currently in closed beta and a password is required.</small>
      </div>
      <!-- Attendees -->
      <div class="col-12 flex align-items-center">
        <label for="attendees">Attendees </label>
        <Button icon="pi pi-question-circle"
                class="p-button-rounded p-button-sm p-button-text"
                @click="showAttendeeInfo=!showAttendeeInfo" />
      </div>
      <Message v-show="showAttendeeInfo"
               class="col-12 mt-0 mb-1"
               severity="info"
               :closable="false">
        Attendees can be an email, a name or a combination.<br>
        Example: 'Stefan Fabian &lt;stefanfabian@somedomain.com&gt;'<br>
        Note: The user will not receive emails if only a name is given.
      </Message>
      <Chips id="attendees"
             class="col-12"
             :model-value="attendees"
             :allow-duplicate="false"
             :add-on-blur="true"
             separator=","
             @update:model-value="$emit('update:attendees', $event)" />
      <div class="col-12 field mt-4">
        <div class="field-checkbox">
          <Checkbox id="openpoll"
                    :binary="true"
                    :model-value="isOpen"
                    @update:model-value="$emit('update:isOpen', $event)" />
          <label for="openpoll">Open for additional attendees</label>
        </div>
      </div>
      <!-- Date range -->
      <div class="grid col-12 lg:col-6 field">
        <label for="weeksDropdown"
               class="col-fixed mt-2">Find a meeting within:</label>
        <Dropdown id="weeksDropdown"
                  v-model="weekOption"
                  :options="weekOptions"
                  option-label="name"
                  option-value="value"
                  class="col" />
      </div>
      <div class="field col-6 lg:col-3 grid">
        <label for="startDate"
               class="col-fixed mt-2">from</label>
        <PrimeCalendar id="startDate"
                       v-model="start"
                       class="col"
                       date-format="dd.mm.y"
                       :min-date="today"
                       :touch-u-i="true" />
      </div>
      <div v-show="weekOption === 0"
           class="field col-6 lg:col-3 grid">
        <label for="endDate"
               class="col-fixed mt-2">until</label>
        <PrimeCalendar id="endDate"
                       v-model="end"
                       class="col"
                       date-format="dd.mm.y"
                       :min-date="today"
                       :touch-u-i="true" />
      </div>
      <div class="col-12 mb-4">
        <span class="text-bold">Select the dates on which the meeting may take place:</span>
      </div>
      <WeekdaySelect :start="start"
                     :end="end"
                     :date-range="dateRange"
                     style="width: 100%"
                     @update:date-range="$emit('update:dateRange', $event)" />

      <!-- Deadline -->
      <div class="field col-12 mt-4">
        <div class="field-checkbox">
          <Checkbox id="deadline"
                    v-model="isDeadlineActive"
                    :binary="true" />
          <label for="deadline">Poll Deadline</label>
        </div>
        <div v-show="deadlineActive"
             class="field ml-4">
          <PrimeCalendar v-model="deadlineDateTime"
                         date-format="dd.mm.y"
                         :show-time="true"
                         :step-minute="5" />
        </div>
        <div v-show="deadlineActive"
             class="field ml-3 grid">
          <div class="col-12 md:col-6 field-checkbox">
            <Checkbox id="reminder"
                      v-model="isReminderActive"
                      :binary="true" />
            <label for="reminder">Reminder</label>
          </div>
          <div v-show="reminderActive"
               class="col-12 md:col-6 grid mb-2">
            <div class="col-2">
              <InputNumber v-model="reminderValue"
                           :min="1" />
            </div>
            <div class="col-5">
              <Dropdown v-model="reminderType"
                        :options="['hours', 'days']"
                        @change="updateReminder" />
            </div>
            <div class="col-5 flex align-items-center">
              <span>before deadline.</span>
            </div>
          </div>
          <div class="field-checkbox col-12">
            <Checkbox id="closeAfterDeadline"
                      v-model="closeAfterDeadline"
                      :binary="true" />
            <label for="closeAfterDeadline">Close poll after deadline</label>
          </div>
        </div>
      </div>
      <!-- Advanced Options -->
      <Card class="meeting-options-card">
        <template #title>
          Options
        </template>
        <template #content>
          <!-- E-Mail options -->
          <h3 class="-mt-2">
            E-Mail
          </h3>
          <div class="field-checkbox">
            <Checkbox v-model="notifyAttendees"
                      :binary="true" />
            <label>Send poll link to attendees by email if provided</label>
          </div>
          <div class="field">
            <div class="field-checkbox">
              <Checkbox v-model="notifyOnNewProposal"
                        :binary="true" />
              <label>Receive an email if a new suggestion was added</label>
            </div>
          </div>
          <div class="field-checkbox">
            <Checkbox v-show="!isOpen"
                      v-model="notifyOnCompleteParticipation"
                      :binary="true" />
            <Checkbox v-show="isOpen"
                      :binary="true"
                      :disabled="true" />
            <label>
              Receive an email once everyone participated
              <small v-if="isOpen" class="ml-1"> (Disabled because the meeting is open)</small>
            </label>
          </div>
          <!-- Privacy options -->
          <h3>
            Privacy
          </h3>
          <div class="field-checkbox">
            <Checkbox v-model="anonymousEntries"
                      :binary="true" />
            <label>
              Make blocked entries anonymous.
            </label>
          </div>
          <div class="text-sm ml-4 -mt-2">
            Blocked entries normally only contain start time, end time and the organizer can see whose entry it is.
            If checked, the information whose entry it is will also not be shared with anyone (including you).
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Chips from 'primevue/chips'
import Dropdown from 'primevue/dropdown'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Message from 'primevue/message'
import PrimeCalendar from 'primevue/calendar'
import {addDays, getNextSunday, isSameDay, setHours, toDateOnly} from "./calendar/calendar-helper"
import {parseISO8601Ranges, toISO8601} from './calendar/iso8601'
import WeekdaySelect from "@/components/WeekdaySelect";

export default {
  name: "CreateMeetingConfiguration",
  components: {WeekdaySelect, Card, Checkbox, Chips, Dropdown, InputNumber, InputText, Message, PrimeCalendar},
  props: {
    attendees: {type: Array, default: () => []},
    dateRange: {
      type: String,
      default: () => undefined
    },
    // Which days are selected by default from Su - Sa
    defaultDays: {type: Array, default: () => [false, true, true, true, true, true, false]},
    isOpen: {type: Boolean, default: () => false},
    deadlineActive: {type: Boolean, default: false},
    deadline: {
      type: Date,
      default: () => setHours(addDays(new Date(), 1), 17, 0)
    },
    organizerName: {type: String, default: ''},
    organizerEmail: {type: String, default: ''},
    subject: {type: String, default: ''},
    betaPassword: {type: String, default: ''},
    reminderActive: {type: Boolean, default: true},
    reminder: {
      type: Date,
      default: () => setHours(addDays(new Date(), 1), 14, 0)
    },
    advancedOptions: {
      type: Object,
      default: () => ({
        notifyAttendees: true,
        notifyOnNewProposal: true,
        notifyOnCompleteParticipation: true,
        closeAfterDeadline: true,
        anonymousEntries: false
      })
    }
  },
  emits: [
    'update:attendees', 'update:dateRange', 'update:deadlineActive', 'update:deadline', 'update:isOpen',
    'update:organizerName', 'update:organizerEmail', 'update:reminderActive', 'update:reminder', 'update:subject',
    'update:advancedOptions', 'update:betaPassword'
  ],
  data: () => ({
    weekOptions: [
      {name: 'Custom', value: 0},
      {name: '1 Week', value: 1},
      {name: '2 Weeks', value: 2},
      {name: '3 Weeks', value: 3},
      {name: '4 Weeks', value: 4},
      {name: '5 Weeks', value: 5},
      {name: '6 Weeks', value: 6}
    ],
    weeks: 2,
    start: addDays(new Date(), 2),
    end: getNextSunday(addDays(toDateOnly(new Date()), 2 + (2 - 1) * 7 + 4)),
    reminderValue: 3,
    reminderType: 'hours',
    showAttendeeInfo: false,
    advancedOptionsOpen: false,
  }),
  computed: {
    weekOption: {
      get() {
        return this.weeks
      },
      set(value) {
        this.weeks = value
        if (value === 0) return
        this.end = getNextSunday(addDays(this.start, (value - 1) * 7 + 4))
      }
    },
    isDeadlineActive: {
      get() {
        return this.deadlineActive
      },
      set(value) {
        this.$emit('update:deadlineActive', value)
      }
    },
    deadlineDateTime: {
      get() {
        return this.deadline
      },
      set(value) {
        this.$emit('update:deadline', value)
      }
    },
    isReminderActive: {
      get() {
        return this.reminderActive
      },
      set(value) {
        this.$emit('update:reminderActive', value)
      }
    },
    notifyAttendees: {
      get() {
        return this.advancedOptions.notifyAttendees
      },
      set(value) {
        let advancedOptions = this.advancedOptions
        advancedOptions.notifyAttendees = value
        this.$emit('update:advancedOptions', advancedOptions)
      }
    },
    notifyOnNewProposal: {
      get() {
        return this.advancedOptions.notifyOnNewProposal
      },
      set(value) {
        let advancedOptions = this.advancedOptions
        advancedOptions.notifyOnNewProposal = value
        this.$emit('update:advancedOptions', advancedOptions)
      }
    },
    notifyOnCompleteParticipation: {
      get() {
        return this.advancedOptions.notifyOnCompleteParticipation
      },
      set(value) {
        let advancedOptions = this.advancedOptions
        advancedOptions.notifyOnCompleteParticipation = value
        this.$emit('update:advancedOptions', advancedOptions)
      }
    },
    closeAfterDeadline: {
      get() {
        return this.advancedOptions.closeAfterDeadline
      },
      set(value) {
        let advancedOptions = this.advancedOptions
        advancedOptions.closeAfterDeadline = value
        this.$emit('update:advancedOptions', advancedOptions)
      }
    },
    anonymousEntries: {
      get() {
        return this.advancedOptions.anonymousEntries
      },
      set(value) {
        let advancedOptions = this.advancedOptions
        advancedOptions.anonymousEntries = value
        this.$emit('update:advancedOptions', advancedOptions)
      }
    },
    today() {
      return toDateOnly(new Date())
    }
  },
  watch: {
    deadline() {
      this.updateReminder()
    },
    reminderValue() {
      this.updateReminder()
    },
    reminder() {
      if (!this.deadline || !this.reminder) return
      let msToDeadline = this.deadline - this.reminder
      if (this.reminderType === 'hours') {
        this.reminderValue = Math.round(msToDeadline / (60 * 60 * 1000))
      } else {
        this.reminderValue = Math.round(msToDeadline / (24 * 60 * 60 * 1000))
      }
    },
    start(value, oldValue) {
      let diff = value - oldValue
      let end = new Date(this.end)
      if (this.weekOption === 0) {
        end.setMilliseconds(end.getMilliseconds() + diff)
      } else {
        end = getNextSunday(addDays(value, (this.weekOption - 1) * 7 + 4))
      }
      this.end = end
    },
    end(value, oldValue) {
      let dateRanges = parseISO8601Ranges(this.dateRange)
      let result = []
      for (let range of dateRanges) {
        if (range.end < this.start) continue
        if (range.start > this.end) continue
        if (range.start < this.start) range.start = toDateOnly(this.start)
        if (range.end > this.end) range.end = toDateOnly(this.end)
        result.push(range)
      }
      if (value > oldValue) {
        let start = addDays(oldValue, 1)
        if (start < this.start) start = this.start
        result = this.initRange(result, start, value)
      }
      this.$emit('update:dateRange', toISO8601(result, true))
    }
  },
  beforeMount() {
    if (!this.dateRange) {
      let range = this.initRange([], this.start, this.end)
      this.$emit('update:dateRange', toISO8601(range, true))
    }
  },
  methods: {
    updateReminder() {
      let msToDeadline = this.reminderValue * 60 * 60 * 1000
      if (this.reminderType === 'days') msToDeadline *= 24
      let diff = this.deadline - msToDeadline
      if (isNaN(diff)) return
      let reminder = new Date(diff)
      this.$emit('update:reminder', reminder)
    },
    initRange(range, start, end) {
      let index = range.length
      let tmp = null
      for (let day = new Date(start); day <= end; day.setDate(day.getDate() + 1)) {
        if (this.defaultDays[day.getDay()]) {
          if (tmp === null) tmp = {start: new Date(day), end: new Date(day)}
          else tmp.end = new Date(day)
        } else if (tmp !== null) {
          range.push(tmp)
          tmp = null
        }
      }
      // Combine first new and last if they are connected
      if (index > 0 && range.length > index + 1 && isSameDay(addDays(range[index].end, 1), range[index + 1].start)) {
        range.splice(index, 2, {start: range[index].start, end: range[index + 1].end})
      }
      return range
    }
  }
}
</script>

<style lang="scss">

.meeting-options-card {
  width: 100%;

  .card-title {
    font-size: 1rem
  }
}

</style>