function isSameDay(a, b) {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
}

function _toISO8601Date(date, date_only) {
  if (date_only) {
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
  }
  let tzo = -date.getTimezoneOffset()
  let sign = tzo >= 0 ? '+' : '-'
  tzo = Math.abs(tzo)

  function pad2(num) {
    let norm = Math.floor(num)
    return (norm < 10 ? '0' : '') + norm
  }

  return date.getFullYear() + '-' + pad2(date.getMonth() + 1) + '-' + pad2(date.getDate()) +
    'T' + pad2(date.getHours()) + ':' + pad2(date.getMinutes()) + ':' + pad2(date.getSeconds()) +
    sign + pad2(tzo / 60) + ':' + pad2(tzo % 60);
}

function _toISO8601Range(start, end, date_only) {
  if (isSameDay(start, end)) return _toISO8601Date(start, date_only)
  return _toISO8601Date(start, date_only) + '/' + _toISO8601Date(end, date_only)
}

export function toISO8601(range_or_date, date_only) {
  if (range_or_date instanceof Array) {
    return range_or_date.map(x => toISO8601(x, date_only)).join(',')
  }
  if (range_or_date.start && range_or_date.end) {
    return _toISO8601Range(range_or_date.start, range_or_date.end, date_only)
  }
  return _toISO8601Date(range_or_date, date_only)
}

function _getStartAndEnd(range) {
  let parts = range.trim().split(/\/|--/g)
  let startParts = parts[0].split('-')
  let startYear = parseInt(startParts[0])
  let startMonth = startParts.length > 1 ? parseInt(startParts[1]) - 1 : 0
  let startDate = startParts.length > 2 ? parseInt(startParts[2]) : 1
  let start = new Date(startYear, startMonth, startDate)
  let end = new Date(startYear, startMonth, startDate)
  if (parts.length > 1) {
    // If we also have an end, parse that where missing fields are filled from left to right
    // that means the second date may omit the year or the year and the month which in that case
    // will be equivalent to the start year / month
    let endParts = parts[1].split('-')
    let endYear = startYear
    let endMonth = startMonth
    let endDate = startDate
    let index = 0
    if (endParts.length > 2) {
      endYear = parseInt(endParts[index])
      ++index
    }
    if (endParts.length > 1) {
      endMonth = parseInt(endParts[index]) - 1
      ++index
    }
    if (endParts.length > 0) {
      endDate = parseInt(endParts[index])
    }
    end = new Date(endYear, endMonth, endDate)
  }
  return {start, end}
}

// Currently only supports dates
export function parseISO8601(string) {
  if (typeof string !== 'string') return []
  let ranges = string.split(',')
  let dates = []
  for (let range of ranges) {
    let start_end = _getStartAndEnd(range)
    if (isNaN(start_end.start) || isNaN(start_end.end)) continue
    if (!isSameDay(start_end.start, start_end.end)) {
      dates.push(start_end)
    } else {
      dates.push(start_end.start)
    }
  }
  return dates.length === 1 ? dates[0] : dates
}

export function parseISO8601Ranges(string) {
  if (typeof string !== 'string') return []
  let ranges = string.split(',')
  let dates = []
  for (let range of ranges) {
    let dateRange = _getStartAndEnd(range)
    if (isNaN(dateRange.start) || isNaN(dateRange.end)) continue
    dates.push(dateRange)
  }
  return dates
}
