<template>
  <div>
    Here's an overview over the meeting poll, you've set up.<br>
    Please verify the information listed below and click confirm to create the poll.
    <div class="text-left ml-auto mr-auto inline-block">
      <IconText icon="pi-info-circle">
        The subject of the meeting is:<br>
        <Chip class="text-bold m-1" :label="subject" />
      </IconText>
      <IconText icon="pi-calendar">
        The meeting will be scheduled during the following dates:<br>
        <Chip v-for="range in parseISO8601Ranges(dateRange)"
              :key="range.start"
              class="info m-1"
              :label="getLabelForRange(range)" />
      </IconText>
      <IconText v-if="!deadline"
                icon="pi-clock"
                color="var(--pink-600)">
        There is no deadline specified.
      </IconText>
      <IconText v-else
                icon="pi-clock"
                color="var(--green-600)">
        The deadline for the meeting poll is
        <Chip :label="deadline.toLocaleString('en-GB', {dateStyle: 'medium', timeStyle: 'short'})" />
        <template v-if="reminder">
          and a reminder is scheduled for
          <Chip :label="reminder.toLocaleString('en-GB', {dateStyle: 'medium', timeStyle: 'short'})" />
        </template>
      </IconText>
    </div>
    <table class="meeting-email-overview">
      <tr>
        <td rowspan="2">
          E-Mail Settings
        </td>
        <td>Invitation</td>
        <td>New Proposal</td>
        <td>Reminder</td>
        <td>Participation Complete</td>
      </tr>
      <tr>
        <td>
          <i v-if="options.notifyAttendees"
             class="pi pi-check-circle"
             style="color:var(--green-600)" />
          <i v-else
             class="pi pi-times-circle"
             style="color:var(--pink-600)" />
        </td>
        <td>
          <i v-if="options.notifyOnNewProposal"
             class="pi pi-check-circle"
             style="color:var(--green-600)" />
          <i v-else
             class="pi pi-times-circle"
             style="color:var(--pink-600)" />
        </td>
        <td>
          <i v-if="reminder"
             class="pi pi-check-circle"
             style="color:var(--green-600)" />
          <i v-else
             class="pi pi-times-circle"
             style="color:var(--pink-600)" />
        </td>
        <td>
          <i v-if="!openMeeting && options.notifyOnCompleteParticipation"
             class="pi pi-check-circle"
             style="color:var(--green-600)" />
          <i v-else
             class="pi pi-times-circle"
             style="color:var(--pink-600)" />
        </td>
      </tr>
    </table>
    <div class="attendee-table-wrapper ml-4">
      <h3>Attendees</h3>
      <p>
        <i v-if="openMeeting"
           class="pi pi-check-circle mr-2"
           style="color:var(--green-600)" />
        <i v-else
           class="pi pi-times-circle mr-2"
           style="color:var(--pink-600)" />
        The meeting is {{ openMeeting ? '' : 'not' }} open for additional attendees.
      </p>
      <table v-if="attendees.length > 0"
             class="attendee-preview-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>E-Mail</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(attendee, i) in attendees"
                    :key="'attendee-info-' + i">
            <tr>
              <td>{{ extractName(attendee) }}</td>
              <td>{{ extractEmail(attendee) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Chip from 'primevue/chip'
import {extractEmail, extractName} from '@/lib/email-helpers'
import {isSameDay} from '@/components/calendar/calendar-helper'
import {parseISO8601Ranges} from '@/components/calendar/iso8601'
import IconText from "@/components/IconText";

export default {
  name: "CreateMeetingOverview",
  components: {IconText, Chip},
  props: {
    subject: {type: String, default: ''},
    attendees: {type: Array, default: () => []},
    dateRange: {type: String, default: ''},
    openMeeting: {type: Boolean, default: false},
    options: {type: Object, default: () => ({})},
    reminder: {type: Date, default: new Date()},
    deadline: {type: Date, default: new Date()}
  },
  methods: {
    extractEmail: extractEmail,
    extractName: extractName,
    getLabelForRange(range) {
      if (isSameDay(range.start, range.end))
        return range.start.toLocaleString([], {weekday: 'short', day: 'numeric', month: 'long', year: 'numeric'})
      let options = {weekday: 'short', day: 'numeric', month: 'long'}
      if (range.start.getFullYear() !== new Date().getFullYear() || range.end.getFullYear() !== new Date().getFullYear())
        options.year = '2-digit';
      return range.start.toLocaleString([], options) + ' - ' + range.end.toLocaleString([], options)
    },
    parseISO8601Ranges: parseISO8601Ranges
  }
}
</script>

<style lang="scss">
  .meeting-email-overview {
    width: 100%;
    border-collapse: collapse;

    tr:first-child {
      td:first-child {
        border-bottom: none;
        width: 6rem;
        font-weight: bold;
      }
      td {
        border-bottom: 1px solid #ccc;
      }
    }

    td {
      text-align: center;
      padding: 0.5rem;
    }
  }

  .attendee-preview-table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 0.5rem 1rem;
    }

    thead tr th {
      border-bottom: 1px solid #ccc;
    }

    tbody tr:nth-child(even) {
      background: var(--surface-100);
    }
  }
</style>