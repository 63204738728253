<template>
  <Dialog v-model:visible="visible"
          header="Meeting created"
          class="text-left md:m-6"
          :close-on-escape="false"
          :modal="true"
          :closable="false">
    <p>
      Here's an overview over the attendees you have registered and their participation links.<br>
      If you have specified an e-mail for each attendee, the status indicates whether an e-mail
      with the scheduling link was sent successfully to the participant.<br>
      If sending the mail failed or you did not specify an e-mail for a participant, you can copy
      their unique participation link below their name and manually send it to them.
    </p>
    <div class="attendee-table-wrapper">
      <table class="attendee-table">
        <thead>
          <tr>
            <th />
            <th>Attendee</th>
            <th>E-Mail</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(attendee, i) in attendees"
                    :key="'attendee-info-' + i">
            <tr>
              <td>
                <Button :icon="isOpen[i] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                        class="p-button-rounded  p-button-text"
                        @click="() => toggleOpen(i)" />
              </td>
              <td>{{ attendee.name }}</td>
              <td>{{ attendee.email }}</td>
              <td>
                <Chip v-if="!options.notifyAttendees"
                      label="Disabled" />
                <Chip v-else-if="attendee.emailSent"
                      label="Sent"
                      icon="pi pi-check-circle"
                      class="info" />
                <Chip v-else-if="attendee.emailFailed"
                      label="Failed"
                      icon="pi pi-times-circle"
                      class="danger" />
                <Chip v-else
                      label="No E-Mail"
                      class="warning" />
              </td>
            </tr>
            <tr v-show="isOpen[i]">
              <td />
              <td colspan="3">
                <div class="p-inputgroup">
                  <input-text :ref="el => { if (el) inputs[i] = el }"
                              type="text"
                              class="p-input p-component"
                              :value="getLinkForAttendee(attendee)"
                              readonly="true" />
                  <Button class="pi pi-copy"
                          @click="() => copyLinkAt(i)" />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="openMeeting"
           class="field mt-2">
        <label for="open-poll-link">Open Poll Link:</label>
        <div class="p-inputgroup">
          <input-text id="open-poll-link"
                      ref="openPollInput"
                      type="text"
                      class="p-input p-component"
                      :value="openPollLink"
                      readonly="true" />
          <Button class="pi pi-copy"
                  @click="() => copyLink($refs['openPollInput'].$el)" />
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Go To Meeting"
              autofocus
              @click="goToMeeting" />
    </template>
  </Dialog>
</template>

<script>
import {ref, onBeforeUpdate} from 'vue'
import Button from 'primevue/button'
import Chip from 'primevue/chip'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import {getMeetingPollLink} from '@/api/openmeet-api'

export default {
  name: "MeetingCreatedDialog",
  components: {Button, Chip, Dialog, InputText},
  props: {
    meetingId: {type: String, default: ''},
    organizerId: {type: String, default: ''},
    attendees: {type: Array, default: () => []},
    openMeeting: {type: Boolean, default: false},
    openId: {type: String, default: ''},
    openKey: {type: String, default: ''},
    options: {type: Object, default: () => ({})},
    modelValue: {type: Boolean, default: false}
  },
  emits: ['update:modelValue'],
  setup() {
    const inputs = ref([])
    onBeforeUpdate(() => {
      inputs.value = []
    })
    return {inputs}
  },
  data: () => ({
    visible: false,
    open: []
  }),
  computed: {
    isOpen() {
      let open = this.open || []
      for (let i = open.length; i < this.attendees.length; ++i)
        open.push(this.attendees.length > i && !this.attendees[i].emailSent)
      return open
    },
    openPollLink() {
      return getMeetingPollLink(this.meetingId, this.openId, this.openKey)
    }
  },
  watch: {
    modelValue(value) {
      this.visible = value
    },
    visible(value) {
      this.$emit('update:modelValue', value)
    }
  },
  methods: {
    getLinkForAttendee(attendee) {
      return getMeetingPollLink(this.meetingId, attendee.id, attendee.key)
    },
    copyLink(input) {
      input.select()
      document.execCommand('copy')
      this.$toast.add({severity: 'info', summary: 'Copied!', detail: 'Link copied to clipboard.', life: 3000})
    },
    copyLinkAt(i) {
      let input = this.inputs[i].$el
      if (!input) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Copying failed!',
          detail: 'Perhaps you need to update your browser.',
          life: 3000
        })
        return
      }
      this.copyLink(input)
    },
    toggleOpen(i) {
      let open = this.isOpen
      open[i] = !open[i]
      this.open = open
    },
    goToMeeting() {
      let key = ''
      for (let attendee of this.attendees) {
        if (attendee.id !== this.organizerId) continue
        key = attendee.key
        break
      }
      if (key === '') {
        this.$toast.add({
          severity: 'error',
          summary: 'Failed!',
          detail: 'Could not find your meeting link.',
          life: 5000
        })
        return
      }
      this.$router.push({
        path: '/meeting',
        query: {id: this.meetingId, attendee: this.organizerId, key: key}
      })
    }
  }
}
</script>

<style>
.attendee-table-wrapper {
  overflow-y: auto;
}

.attendee-table {
  width: 100%;
  border-collapse: collapse;
}

.attendee-table tr {
  height: 2rem;
}

.attendee-table th:first-child, .attendee-table td:first-child {
  border: none;
  width: 3rem;
}

.attendee-table thead tr th {
  border-bottom: 1px solid #ccc;
}
</style>