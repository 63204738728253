import {parseISO8601Ranges} from './iso8601'

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

export function getPreviousMonday(date) {
  let mondayOffset = date.getDay() === 0 ? 6 : date.getDay() - 1
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - mondayOffset)
}

export function getNextSunday(date) {
  let sundayOffset = date.getDay() === 0 ? 0 : 7 - date.getDay()
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + sundayOffset)
}

export function computeWeeksFromStartToEnd(start, end) {
  let monday = getPreviousMonday(start)
  let sunday = getNextSunday(end)
  return Math.ceil((sunday - monday) / (7 * 24 * 60 * 60 * 1000))
}

export function addDays(date, days) {
  let result = new Date(date)
  result.setDate(date.getDate() + days);
  return result
}

export function getDaysBetween(start, end) {
  return Math.round((end - start) / (24 * 60 * 60 * 1000))
}

export function isSameDay(a, b) {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
}

export function setHours(date, hours, minutes) {
  return new Date(date.setHours(hours, minutes, 0, 0))
}

export function inDateRange(date, range) {
  let dateRanges = typeof range === 'string' ? parseISO8601Ranges(range) : range
  if (!dateRanges) return false
  date = toDateOnly(date)
  for (let dateRange of dateRanges) {
    if (dateRange.start <= date && date <= dateRange.end) return true
  }
  return false
}

export function toDateOnly(datetime) {
  return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate())
}

