import axios from 'axios'

let CONFIG = {
  domain: 'https://schedule.ameeting.de',
  api: 'https://api.ameeting.de'
}

export function setConfig(config) {
  CONFIG = {
    ...CONFIG,
    ...config
  }
}

export function getMeetingPollLink(meetingId, attendeeId, key) {
  return `${CONFIG.domain}/#/meeting?id=${meetingId}&attendee=${attendeeId}&key=${key}`
}

function toISO8601(date) {
  let tzo = -date.getTimezoneOffset()
  let sign = tzo >= 0 ? '+' : '-'
  tzo = Math.abs(tzo)

  function pad2(num) {
    let norm = Math.floor(num)
    return (norm < 10 ? '0' : '') + norm
  }

  return date.getFullYear() + '-' + pad2(date.getMonth() + 1) + '-' + pad2(date.getDate()) +
    'T' + pad2(date.getHours()) + ':' + pad2(date.getMinutes()) + ':' + pad2(date.getSeconds()) +
    sign + pad2(tzo / 60) + ':' + pad2(tzo % 60);
}

// Process for sending
function preprocessCalendarEntries(calendarEntries) {
  let strippedEntries = []
  for (let entry of calendarEntries) {
    strippedEntries.push({
      id: entry.id,
      start: entry.start instanceof Date ? toISO8601(entry.start) : entry.start,
      end: entry.end instanceof Date ? toISO8601(entry.end) : entry.end,
      votes: entry.votes
    })
  }
  return strippedEntries
}

// Process for use in javascript
function processEntries(entries, meeting) {
  for (let entry of entries) {
    if (entry.start) entry.start = new Date(entry.start)
    if (entry.end) entry.end = new Date(entry.end)
    if (entry.votes) {
      for (let key in entry.votes) {
        if (entry.votes[key].users) {
          let users = []
          for (let user of entry.votes[key].users) {
            users.push({id: user, name: meeting.attendees[user].name})
          }
          entry.votes[key].users = users
        }
      }
    }
  }
  return entries
}

function processMeeting(meeting) {
  if (meeting.start) meeting.start = new Date(meeting.start)
  if (meeting.end) meeting.end = new Date(meeting.end)
  if (meeting.deadline) meeting.deadline = new Date(meeting.deadline)
  if (meeting.proposals) meeting.proposals = processEntries(meeting.proposals, meeting)
  if (meeting.entries) meeting.entries = processEntries(meeting.entries, meeting)
  if (meeting.userProposals) meeting.userProposals = processEntries(meeting.userProposals, meeting)
  if (meeting.userEntries) meeting.userEntries = processEntries(meeting.userEntries, meeting)
  return meeting
}

/**
 * Creates a meeting and returns a promise which on success will contain an object structured as follows:
 * @code
 * {
 *   'meetingId': '$MeetingId$',
 *   'organizer': '$organizerId$',
 *   'attendees': {
 *     '$AttendeeId$': {'name': '$AttendeeName$', 'key': '$AttendeeKey$'},
 *     ...
 *   },
 *   'open': [true|false],
 *   'openId': $Id$,
 *   'openKey': $Key$,
 *   'sentEmails': [...],
 *   'failedEmails': [...]
 * }
 * @endcode
 *
 * The first attendee is always the organizer.
 * sentEmails and failedEmails contain the email addresses to which an email was sent or where sending failed.
 *
 * @param organizer The organizer in the format 'FirstName LastName <email@domain.eu>'
 * @param attendees A list of attendees which can be a name, an email or a combination in the same format as for the organizer.
 *    Note that an invitation mail can only be sent if an email is specified.
 * @param subject The meeting's subject.
 * @param dateRange The date range of the meeting in ISO860 format, e.g., '2021-04-21/25,2021-04-29/05-03'
 * @param calendarEntries The calendar entries of the organizer. An array of objects with start and end property as datetime.
 * @param proposals The proposals for meeting slots by the organizer. Also an array of objects with start and end property.
 * @param options The options for the meeting poll.
 *   Example:
 *   @code
 *   {
 *     deadline: '$Datetime$',
 *     reminder: '$Datetime$', // The date and time a reminder will be sent to participants who have not participated yet
 *     advanced: {
 *       closeAfterDeadline: [true|false], // If true, no one can make updates after the deadline
 *       notifyAttendees: [true|false], // If true, send an email to all attendees with participation link
 *       notifyOnNewProposal: [true|false], // If true, notify attendees if new proposal was added after they participated
 *       notifyOnCompleteParticipation: [true|false],  // If true, will send an email to organizer once everyone participated
 *       anonymousEntries: [true|false] // If true, blocked entries will not show whose entry they are
 *     }
 *   }
 *   @endcode
 *   Here $Datetime$ should be a Javascript Date or ISO datetime string.
 * @param open Whether or not the meeting is open to additional attendees. (Default: false)
 * @returns Promise resolving to described object on success.
 */
export function createMeeting({
                                organizer,
                                attendees,
                                subject,
                                dateRange,
                                calendarEntries,
                                proposals,
                                options,
                                open = false
                              }, betaPassword) {
  let entries = preprocessCalendarEntries(calendarEntries)
  let stripped_proposals = preprocessCalendarEntries(proposals)
  if (options) {
    if (options.deadline instanceof Date) options.deadline = toISO8601(options.deadline)
    if (options.reminder instanceof Date) options.reminder = toISO8601(options.reminder)
  }
  return axios.post(CONFIG.api + '/create-meeting', {
    organizer,
    attendees,
    subject,
    dateRange,
    entries,
    proposals: stripped_proposals,
    options,
    open,
    betaPassword
  }).then(result => processMeeting(result.data))
}

/**
 * Retrieves the given meeting for the attendee with the given attendeeId and key in the following format:
 * @code
 * {
 *   'meetingId': '$MeetingId$',
 *   'organizer': '$OrganizerName$',
 *   'subject': str,
 *   'dateRange': $DATERANGE$,
 *   'entries': [{start, end, user}, ...],
 *   'proposals': [{id, start, end, votes: {accept: {users: [{PublicAttendeeId}, ...], selected}, reject: ...}}, ...],
 *   'userEntries': [{start, end}, ...],
 *   'userName': $AttendeeName$,
 *   'userProposals': [{id, start, end, votes}, ...],
 *   'participated': [true|false], // true if the user already participated, otherwise false
 *   'deadline': $Datetime$,
 *   'closed': [true|false], // Whether the meeting is closed for participation / updates
 *   'attendees': {
 *      $PublicAttendeeId$: { // Public id is only valid in this message and used, e.g., in a proposal's users or entries' user
 *        'id': $AttendeeId$, // Only available for organizer
 *        'name': $AttendeeName$,
 *        'key': $AttendeeKey$, // Only available for organizer
 *        'participated': [true|false]
 *      }, ...
 *   },
 *   // The organizer will also get
 *   'open': [true|false], // Whether the meeting is open for new participants
 *   'openId': $AttendeeId$,
 *   'openKey': $AttendeeKey$,
 * }
 * @endcode
 * where start and end are the dates marking the range within which the meeting is be scheduled.
 * $UNAVAILABLE$ is an array of arrays where each array contains 7 integers for each day from monday to sunday
 *   marking how many people can not do that day.
 * $DATERANGE$ is a string which may be a list of dates or date ranges separated by comma following ISO format,
 *   e.g., '2021-07-02--2022-07-09' or '2021-07-02/08-01' or '2021-07-02,2021-07-05/08'
 *
 * @param id The meeting id.
 * @param attendee The id of the attendee.
 * @param key The key for the attendee if applicable.
 * @returns Promise resolving to described object on success.
 */
export function getMeeting(id, attendee, key) {
  return axios.post(CONFIG.api + '/meetings/' + id, {attendee, key})
    .then(result => processMeeting(result.data))
}

/**
 * Updates the given meeting for the attendee with the given values and returns the updated meeting.
 * For the structure of the meeting check the docs for getMeeting.
 * Additionally, this may also contain a string 'attendeeId' and a string/int 'attendeeKey' if you updated with an
 * open id and key. These represent the new id and key for your attendee.
 * TODO: Document parameters
 * @param id
 * @param attendee
 * @param key
 * @param name
 * @param email
 * @param calendarEntries
 * @param proposals
 * @param open
 * @returns Promise
 */
export function updateMeeting({id, attendee, key, name, email, calendarEntries, proposals, open}) {
  let entries = preprocessCalendarEntries(calendarEntries)
  let stripped_proposals = preprocessCalendarEntries(proposals)
  return axios.patch(CONFIG.api + '/meetings/' + id, {
    attendee, key, name, email, entries, proposals: stripped_proposals, open
  }).then(result => processMeeting(result.data))
}
