import { createApp } from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import App from './App.vue'

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import HomePage from './pages/HomePage.vue'
import CreateMeetingPage from './pages/CreateMeetingPage.vue'
import MeetingPage from './pages/MeetingPage.vue'

const routes = [
  {path: '/', component: HomePage},
  {path: '/create-meeting', component: CreateMeetingPage},
  {path: '/meeting', component: MeetingPage}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

createApp(App)
  .use(PrimeVue)
  .use(ToastService)
  .directive('tooltip', Tooltip)
  .use(router)
  .component('Button', Button)
  .mount('#app')
