<template>
  <Dialog v-bind="$attrs"
          header="Quick Add"
          :breakpoints="{'640px': '100vw'}">
    <div class="p-fluid formgrid">
      <div class="col-fixed field">
        <label>Type</label>
        <Dropdown :options="['Proposal', 'Blocked']" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'

export default {
  name: "QuickAddDialog",
  components: {Dialog, Dropdown},
}
</script>

<style scoped>

</style>