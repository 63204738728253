class GoogleCalendar {
  constructor(entry) {
    this.id = entry.id
    this.color = entry.backgroundColor
    this.name = entry.summary
    this.primary = entry.primary || false
  }

  async getEvents(from, to, token) {
    let events = await gapi.client.calendar.events.list({
      'calendarId': this.id,
      'timeMin': from && from.toISOString(),
      'timeMax': to && to.toISOString(),
      'maxAttendees': 1,
      'showDeleted': false,
      'singleEvents': true,
      'orderBy': 'startTime',
      'pageToken': token
    })
    return {
      events: events.result.items.map(event => ({
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        summary: event.summary
      })),
      nextToken: events.result.nextPageToken
    }
  }
}

export default class GoogleCalendarProvider {
  constructor() {
    this.id = 'google'
    this.displayName = 'Google Calendar'
    this.icon = 'pi-google'
  }
  init() {
    return new Promise((resolve, reject) => this.__loadGoogleCalendarApi(resolve, reject))
  }

  __loadGoogleCalendarApi(resolve, reject) {
    let script = document.getElementById("google-api-script")
    if (!script) {
      script = document.createElement('script')
      script.id = 'google-api-script'
      script.src = 'https://apis.google.com/js/api.js'
      script.onload = () => { this.__loadGoogleCalendarApi(resolve, reject) }
      document.body.appendChild(script)
      return
    }

    function loadCalendar() {
      let auth = gapi.auth2.getAuthInstance()
      if (!auth.isSignedIn.get()) {
        auth.isSignedIn.listen(loadCalendar)
        auth.signIn()
        return
      }
      gapi.client.load('calendar', 'v3', function () {
        resolve()
      })
    }

    try {
      gapi.load('client:auth2', function () {
          gapi.client.init({
            apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: 'https://www.googleapis.com/auth/calendar.readonly'
          }).then(loadCalendar, reject)
      })
    } catch (e) {
      reject(e)
    }
  }

  async getCalendars() {
    let response = await gapi.client.calendar.calendarList.list()
    return response.result && response.result.items && response.result.items.map(item => new GoogleCalendar(item)) || []
  }
}

