<template>
  <div class="blocked-slot"
       :style="{'top': top + 'rem', 'height': height + 'rem'}"
       aria-haspopup="true"
       @click="showCalendarDetails(this, {start, end, entries})">
    <div class="blocked-slot-background" />
    <div v-if="showCount"
         class="blocked-count">
      <span>
        {{ entries.length }}
      </span>
    </div>
  </div>
</template>

<script>
import {toDateOnly} from '../calendar-helper'
import Style from '../scss/_export.module.scss'

const TIMESLOT_HEIGHT_REM = parseFloat(Style.timeslotheight)
const DAY_HEIGHT_REM = TIMESLOT_HEIGHT_REM * 48

export default {
  name: "BlockedEntry",
  components: {},
  inject: ['showCalendarDetails'],
  props: {
    start: Date,
    end: Date,
    entries: {type: Array, default: () => []},
    showCount: Boolean
  },
  computed: {
    top() {
      let countTimeSlots = (this.start - toDateOnly(this.start)) / (30 * 60 * 1000)
      return Math.max(0, countTimeSlots * TIMESLOT_HEIGHT_REM)
    },
    height() {
      return Math.min(DAY_HEIGHT_REM - this.top,
          (this.end - this.start) / (30 * 60 * 1000) * TIMESLOT_HEIGHT_REM)
    }
  }
}
</script>

<style lang="scss">
@import "../scss/variables";

.blocked-slot {
  position: absolute;
  width: 2rem;
  cursor: pointer;

  .blocked-slot-background {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    margin-left: -0.5rem;
    width: 1rem;
    background: $blocked-color;
    border-radius: $border-radius;
  }

  .blocked-count {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.75rem;
    margin-left: -0.75rem;
    border-radius: 50%;
    background: $blocked-color;
    color: $blocked-text-color;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>