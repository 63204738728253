<template>
  <div class="multistepview">
    <div class="multistepview-step-header">
      <template v-for="(step, i) of steps"
                :key="'step-header-'+i">
        <div class="multistepview-step-header-item"
             :class="{'active-step': activeIndex === i}">
          <span>{{ (i+1) }}</span>
          <span class="multistepview-step-name">
            {{ step.props && step.props.name || ('Step ' + (i + 1)) }}
          </span>
        </div>
      </template>
    </div>
    <template v-for="(step, i) of steps"
              :key="'steps-panel-'+i">
      <div v-show="activeIndex === i"
           class="multistepview-panel">
        <component :is="step" />
      </div>
    </template>
    <div class="multistepview-buttons">
      <div>
        <Button v-show="activeIndex > 0"
                class="multistepview-button-previous"
                label="Back"
                @click="previous" />
      </div>
      <div>
        <Button v-show="activeIndex < steps.length"
                class="multistepview-button-next"
                :label="activeIndex === steps.length - 1 ? doneLabel : 'Next'"
                :disabled="!canGoNext"
                @click="next" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiStepView",
  props: {doneLabel: {type: String, default: () => 'Send'}},
  emits: ['done'],
  data: () => ({
    activeIndex: 0
  }),
  computed: {
    canGoNext() {
      let activeStep = this.steps[this.activeIndex]
      return !activeStep.props || activeStep.props.completed !== false
    },
    steps() {
      const steps = []
      this.$slots.default().forEach(child => {
        if (this.isPanel(child)) {
          steps.push(child)
        } else if (child.children && child.children instanceof Array) {
          child.children.forEach(nested => {
            if (this.isPanel(nested)) steps.push(nested)
          })
        }
      })
      return steps
    }
  },
  methods: {
    isPanel(el) {
      return el.type.name === 'MultiStepPanel'
    },
    previous() {
      --this.activeIndex
    },
    next() {
      if (this.activeIndex === this.steps.length - 1) {
        this.$emit('done')
        return
      }
      ++this.activeIndex
    },
  }
}
</script>

<style lang="scss">

.multistepview {
  display: flex;
  flex-direction: column;
}

.multistepview-step-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.multistepview-step-header-item {
  display: flex;
  flex-direction: column;
  margin: 1rem;

  span:first-child {
    align-self: center;
    margin-bottom: 0.25rem;
    border: 0.1rem solid;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.multistepview-step-name {
}

.multistepview-step-header-item.active-step {
  color: var(--primary-color);
}

.multistepview-buttons {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  width: 100%;
  max-width: 720px;
  align-self: center;

  > div {
    display: flex;
    flex: 1;
  }

  > div:first-child {
    justify-content: flex-start;
  }

  > div:last-child {
    justify-content: flex-end;
  }
}
</style>