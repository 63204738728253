<template>
  <div class="proposal"
       :style="{'top': top + 'rem', 'min-height': height + 'rem', 'height': expanded ? 'auto' : height + 'rem'}"
       :class="{'proposal-expanded': expanded}"
       aria-haspopup="true"
       @click="expand">
    <div class="proposal-header">
      <Button class="p-button-icon p-button-text" :icon="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'" />
      <span class="hidden md:inline">{{ formatTime(entry.start) }} - {{ formatTime(entry.end) }}</span>
      <SelectButton v-model="selectedOption"
                    :options="['accept', 'maybe', 'reject']"
                    :clearable="true"
                    :disabled="disabled"
                    @click.stop>
        <template #option="slotProps">
          <i class="pi"
             :class="{'pi-check': slotProps.option === 'accept', 'pi-question-circle': slotProps.option === 'maybe', 'pi-times': slotProps.option === 'reject'}" />
          <span>
            {{ counts[slotProps.option] || 0 }}
          </span>
        </template>
      </SelectButton>
    </div>
    <div class="block md:hidden proposal-time-info">
      {{ formatTime(entry.start) }} - {{ formatTime(entry.end) }}
    </div>
    <div ref="content"
         class="proposal-content">
      <template v-if="expanded || contentHeight >= 2">
        <i class="pi pi-check" />
        <span :style="{'max-height': acceptMaxHeight + 'rem'}">
          {{ acceptUsers.length === 0 ? 'No one' : acceptUsers.map(u => u.name).sort().join(', ') }}
        </span>
      </template>
      <template v-if="expanded || true">
        <i class="pi pi-times" />
        <span :style="{'max-height': rejectMaxHeight + 'rem'}">
          {{ rejectUsers.length === 0 ? 'No one' : rejectUsers.map(u => u.name).sort().join(', ') }}
        </span>
      </template>
      <template v-if="expanded || contentHeight >= 3">
        <i class="pi pi-question" />
        <span :style="{'max-height': maybeMaxHeight + 'rem'}">
          {{ maybeUsers.length === 0 ? 'No one' : maybeUsers.map(u => u.name).sort().join(', ') }}
        </span>
      </template>
      <Button v-if="!disabled && !entry.readonly" v-show="expanded && !deleteRequested"
              class="delete-button p-button-danger p-button-sm"
              @click.stop="deleteProposal">
        Delete
      </Button>
      <Button v-show="deleteRequested" class="confirm-delete-button p-button-danger p-button-sm"
              @click.stop="confirmDelete">
        Confirm
      </Button>
    </div>
    <div class="proposal-border"
         :class="selectedOption + '-border '" />
  </div>
</template>

<script>
import Button from 'primevue/button'
import SelectButton from './SelectButton'
import {toDateOnly} from '../calendar-helper'
import Style from '../scss/_export.module.scss'
import ReactiveSize from './reactive-size'

const TIMESLOT_HEIGHT_REM = parseFloat(Style.timeslotheight)
const DAY_HEIGHT_REM = TIMESLOT_HEIGHT_REM * 48

export default {
  name: "ProposalEntry",
  components: {Button, SelectButton},
  inject: ['formatTime', 'remToPx', 'pxToRem', 'userId'],
  props: {
    start: Date,
    end: Date,
    entry: {type: Object, default: () => null},
    disabled: Boolean
  },
  emits: ['delete'],
  data: () => ({
    expanded: false,
    deleteRequested: false,
    element: null
  }),
  computed: {
    top() {
      let countTimeSlots = (this.start - toDateOnly(this.start)) / (30 * 60 * 1000)
      return Math.max(0, countTimeSlots * TIMESLOT_HEIGHT_REM)
    },
    height() {
      return Math.min(DAY_HEIGHT_REM - this.top,
          (this.end - this.start) / (30 * 60 * 1000) * TIMESLOT_HEIGHT_REM)
    },
    contentHeight() {
      if (this.element == null) return 0
      return this.pxToRem(this.element.height)
    },
    acceptMaxHeight() {
      if (this.expanded) return 1000
      return this.height <= 8 ? Math.ceil(this.contentHeight / 2) : Math.ceil(this.contentHeight / 3)
    },
    rejectMaxHeight() {
      if (this.expanded) return 1000
      if (this.height <= 8)
        return Math.max(1, Math.floor(this.contentHeight / 2))
      let fullHeight = Math.ceil(this.contentHeight / 3)
      return (this.contentHeight % 3 !== 1) ? fullHeight : fullHeight - 1
    },
    maybeMaxHeight() {
      if (this.expanded) return 1000
      let fullHeight = Math.ceil(this.contentHeight / 3)
      return (this.contentHeight % 3 === 0) ? fullHeight : fullHeight - 1
    },
    votes() {
      return this.entry && this.entry.votes || {}
    },
    acceptUsers() {
      if (!this.votes.accept) return []
      let users = Array.from(this.votes.accept.users || [])
      let indexUser = users.findIndex(val => val.id === this.userId)
      if (indexUser !== -1) users.splice(indexUser, 1)
      return (this.votes.accept.selected ? [{name: 'You'}] : []).concat(users)
    },
    maybeUsers() {
      if (!this.votes.maybe) return []
      let users = Array.from(this.votes.maybe.users || [])
      let indexUser = users.findIndex(val => val.id === this.userId)
      if (indexUser !== -1) users.splice(indexUser, 1)
      return (this.votes.maybe.selected ? [{name: 'You'}] : []).concat(users)
    },
    rejectUsers() {
      if (!this.votes.reject) return []
      let users = Array.from(this.votes.reject.users || [])
      let indexUser = users.findIndex(val => val.id === this.userId)
      if (indexUser !== -1) users.splice(indexUser, 1)
      return (this.votes.reject.selected ? [{name: 'You'}] : []).concat(users)
    },
    acceptCount() {
      return this.acceptUsers.length
    },
    maybeCount() {
      return this.maybeUsers.length
    },
    rejectCount() {
      return this.rejectUsers.length
    },
    counts() {
      return {'accept': this.acceptCount, 'maybe': this.maybeCount, 'reject': this.rejectCount}
    },
    selectedOption: {
      get() {
        if (this.entry.votes) {
          for (let key in this.entry.votes) {
            if (this.entry.votes[key] && this.entry.votes[key].selected) return key
          }
        }
        return 'unknown'
      },
      set(value) {
        let entry = this.entry
        if (!entry.votes) entry.votes = {}
        for (let key in entry.votes) {
          if (entry.votes[key] && entry.votes[key].selected) entry.votes[key].selected = false
        }
        if (!value) return
        if (!entry.votes[value]) entry.votes[value] = {count: 0, selected: false}
        entry.votes[value].selected = true
      }
    }
  },
  mounted() {
    this.element = new ReactiveSize(this.$refs.content)
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
      this.deleteRequested = false
    },
    deleteProposal() {
      this.deleteRequested = true
    },
    confirmDelete() {
      if (this.disabled) return
      this.$emit('delete', this.entry)
    }
  }
}
</script>

<style lang="scss">
@import "../scss/variables";

.proposal-border {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

.accept-border {
  border: 0.15rem solid $accept-color;
}

.maybe-border {
  border: 0.15rem solid $maybe-color;
}

.reject-border {
  border: 0.15rem solid $reject-color;
}

.unknown-border {
  border: 0.15rem solid $unknown-color;
}

.proposal.unknown-border {
  .p-selectbutton .p-button.p-component {
    background-color: $unknown-color;
  }
}

.proposal.proposal-expanded {
  z-index: 3 !important;

  .proposal-content {
    padding-bottom: 3rem;

    span:after {
      display: none;
    }
  }
}

.proposal {
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  border-radius: $border-radius;
  background: $proposal-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;

  .proposal-header {
    display: flex;
    max-height: 100%;
    background: $proposal-color;
    z-index: 1;

    > span {
      align-self: center;
      margin: 0 0.5rem;
      font-weight: bold;
    }

    .p-selectbutton {
      display: flex;
      flex-grow: 1;
      height: 2rem;
      max-height: 100%;

      .p-button.p-component {
        padding: 0;
        background: #ffffff44;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        * {
          margin: 0 0.25rem;
        }

        span {
          margin-top: 0.2rem;
        }
      }

      .p-button.p-component.highlight[aria-label=accept] {
        background-color: $accept-color;
        color: $accept-text-color;
      }

      .p-button.p-component.highlight[aria-label=maybe] {
        background-color: $maybe-color;
        color: $maybe-text-color;
      }

      .p-button.p-component.highlight[aria-label=reject] {
        background-color: $reject-color;
        color: $reject-text-color;
      }
    }

    .p-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 2rem;
      max-height: 100%;
    }
  }

  .proposal-content.proposal-content-full {
    row-gap: 0.5rem;
  }

  .proposal-time-info {
    height: 1rem;
    line-height: 1rem;
    grid-column: 1/3;
    margin: 0.5rem 0;
  }

  .proposal-content {
    display: grid;
    grid-template-columns: 2rem 1fr;
    padding: 0.5rem 0.5rem;
    row-gap: 1rem;
    flex-shrink: 1;
    overflow: hidden;
    align-content: start;

    > .pi {
      align-self: center;
      justify-self: center;
      padding: 0.1rem;
      font-size: 0.8rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
    }

    > .pi-check {
      background: $accept-color;
      color: white;
    }

    > .pi-times {
      background: $reject-color;
      color: white;
    }

    > .pi-question {
      background: $maybe-color;
      color: grey;
    }

    span {
      position: relative;
      text-align: left;
      line-height: 1rem;
      overflow: hidden;
      min-height: 1rem;
    }

    span:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%;
      min-width: 8rem;
      height: 1em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), $proposal-color 50%);
    }

    .expand-button {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #ffffff44;
      color: var(--text-color);
      z-index: 0;
    }

    .delete-button {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .confirm-delete-button {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
}
</style>