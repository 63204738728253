import ICAL from 'ical.js'
import {inDateRange} from "@/components/calendar/calendar-helper";

class ICalCalendar {
  constructor(ical) {
    this.id = 'ical'
    this.color = ''
    this.summary = 'ical file'
    this.primary = true
    this.ical = ical
  }

  async getEvents(from, to) {
    let calendar = new ICAL.Component(this.ical)
    let events = []
    for (let entry of calendar.getAllSubcomponents('vevent')) {
      // eslint-disable-next-line no-undef
      let event = new ICAL.Event(entry)
      // Only startDate can be  used here since for recurring events endDate is the end of the first occurrence
      let start = event.startDate.toJSDate()
      if (start > to) continue
      if (event.isRecurring()) {
        let iterator = event.iterator()
        for (let next = iterator.next(); next; next = iterator.next()) {
          start = next.toJSDate()
          if (start > to) break
          let end = new Date(start)
          end.setSeconds(end.getSeconds() + event.duration.toSeconds())
          if (end < from) continue
          events.push({
            start: start,
            end: end,
            type: 'blocked',
            summary: entry.getFirstPropertyValue('summary')
          })
        }
      } else {
        let end = event.endDate.toJSDate()
        if (start > to || end < from) continue
        events.push({
          start: start,
          end: end,
          summary: entry.getFirstPropertyValue('summary')
        })
      }
    }
    return {events: events}
  }
}

export default class ICalProvider {
  constructor() {
    this.id = 'ical'
    this.displayName = 'ICS File'
    this.icon = 'pi-file'
    this.autoImportAll = true
  }
  init() {
    let input = document.getElementById("ical-provider-input")
    if (!input) {
      input = document.createElement("input")
      input.id = "ical-provider-input"
      input.type = "file"
      input.multiple = true
      input.accept = ".ics"
      input.style.display = "none"
      input.addEventListener("change", this.handleInput.bind(this))
    }
    this.input = input
    this.fileReader = new FileReader()
    this.fileReader.onload = this.processFile.bind(this)
    this.fileReader.onerror = this.handleFileError.bind(this)
    return Promise.resolve()
  }

  handleInput() {
    if (!this.resolve) return
    // Load files
    this.fileQueue = [...this.input.files]
    this.fileReader.readAsText(this.fileQueue.shift())
  }

  processFile() {
    try {
      let calData = ICAL.parse(this.fileReader.result)
      this.calendars.push(new ICalCalendar(calData))
    } catch (e) {
      // Failed to parse file! // TODO: Handle this
    }
    if (!this.fileQueue.length) {
      this.resolve([...this.calendars])
      this.resolve = null
      return
    }
    this.fileReader.readAsText(this.fileQueue.shift())
  }

  handleFileError() {
    // Opening the file failed! // TODO: Handle this
  }

  async getCalendars() {
    this.calendars = []
    this.input.click()
    return new Promise(resolve => this.resolve = resolve)
  }
}
