<template>
  <p class="col-12 text-bold">
    Currently in closed beta.<br>
    You need a beta password to create a meeting.<br>
    If you don't have the beta password, please check back in a few weeks/months.
  </p>
  <div class="col-12 justify-content-center align-content-center align-items-center">
    <Button class="p-button-lg"
            style="width: auto"
            @click="schedule">
      Schedule a meeting
    </Button>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  components: {},
  methods: {
    schedule() {
      this.$router.push("/create-meeting")
    }
  }
}
</script>

<style scoped>

</style>