
export function extractEmail(email) {
  let posAngleBracket = email.indexOf('<')
  if (posAngleBracket === -1) {
    if (email.indexOf('@') !== -1) return email
    return ""
  }
  let endPosAngleBracket = email.indexOf('>', posAngleBracket)
  return email.substr(posAngleBracket + 1, endPosAngleBracket - posAngleBracket - 1)
}

export function extractName(email) {
  let posAngleBracket = email.indexOf('<')
  if (posAngleBracket === -1) {
    let posAt = email.indexOf('@')
    if (posAt === -1) return email
    return ''
  }
  return email.substr(0, posAngleBracket).trim()
}
