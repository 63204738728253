<template>
  <div class="p-fluid proposals">
    <div>
      <div v-if="proposals.length === 0"
           class="flex align-items-center justify-content-center m-6">
        <div class="m-2">
          <i class="pi pi-calendar"
             style="font-size:4rem" />
        </div>
        <div class="text-left">
          <div class="text-bold mb-1">
            No proposals to show yet.
          </div>
          <div class="">
            Add some in the calendar!
          </div>
        </div>
      </div>
      <table v-else>
        <thead>
          <tr class="day-row">
            <th />
            <template v-for="(dayInfo, index) in proposalDayInfos"
                      :key="'proposal-day-'+dayInfo.day">
              <th :colspan="dayInfo.count">
                {{ dayInfo.day }}
              </th>
              <th v-if="index !== proposalDayInfos.length - 1"
                  class="space" />
            </template>
          </tr>
          <tr>
            <th />
            <template v-for="(proposal, index) in proposals"
                      :key="'proposal-' + proposal.start+'-'+proposal.end">
              <th :class="columnClasses[index]">
                {{ proposal.start.toLocaleString('en-GB', {timeStyle: 'short'}) }} -
                {{ proposal.end.toLocaleString('en-GB', {timeStyle: 'short'}) }}
              </th>
              <th v-if="columnClasses[index].includes('proposal-col-last-day') && index !== proposals.length - 1"
                  class="space" />
            </template>
          </tr>
        </thead>
        <tbody>
          <!-- Summary -->
          <tr>
            <td><span class="text-bold">Accept (+Maybe)</span></td>
            <template v-for="(proposal, index) in proposals"
                      :key="'proposal-summary-' + proposal.start+'-'+proposal.end">
              <td :class="columnClasses[index]">
                {{ getAcceptedCount(proposal) }}
                (+{{ getMaybeCount(proposal) }})
              </td>
              <td v-if="columnClasses[index].includes('proposal-col-last-day') && index !== proposals.length - 1"
                  class="space" />
            </template>
          </tr>
          <tr class="space" />
          <!-- User first -->
          <tr>
            <td class="text-bold">
              You
            </td>
            <template v-for="(proposal, index) in proposals"
                      :key="'answer--user--proposal-' + proposal.start+'-'+proposal.end">
              <td v-if="!disabled"
                  :class="userColumnClasses[index]">
                <SelectButton :model-value="userSelections[index]"
                              :options="['accept', 'maybe', 'reject']"
                              :clearable="true"
                              @update:model-value="updateUserSelection(index, $event)">
                  <template #option="slotProps">
                    <i class="pi"
                       :class="{'pi-check': slotProps.option === 'accept', 'pi-exclamation-circle': slotProps.option === 'maybe', 'pi-times': slotProps.option === 'reject'}" />
                  </template>
                </SelectButton>
              </td>
              <td v-else
                  :class="userColumnClasses[index]">
                <i v-if="userSelections[index] === 'accept'"
                   class="pi pi-check" />
                <i v-else-if="userSelections[index] === 'maybe'"
                   class="pi pi-exclamation-circle" />
                <i v-else-if="userSelections[index] === 'reject'"
                   class="pi pi-times" />
                <i v-else
                   class="pi pi-question" />
              </td>
              <td v-if="columnClasses[index].includes('proposal-col-last-day') && index !== proposals.length - 1"
                  class="space" />
            </template>
          </tr>
          <!-- Other attendees -->
          <template v-for="(attendee, index) in attendees">
            <tr v-if="parseInt(index) !== parseInt(userId)"
                :key="'prop-attendee-'+attendee.name">
              <td :title="attendee.name">
                {{ attendee.name }}
              </td>
              <template v-for="(proposal, proposalIndex) in proposals"
                        :key="'answer-'+attendee.name+'-proposal-' + proposal.start+'-'+proposal.end">
                <td :class="attendeeColumnClasses[index][proposalIndex]">
                  <i v-if="isAccepted(proposal, index)"
                     class="pi pi-check" />
                  <i v-else-if="isMaybe(proposal, index)"
                     class="pi pi-exclamation-circle" />
                  <i v-else-if="isRejected(proposal, index)"
                     class="pi pi-times" />
                  <i v-else
                     class="pi pi-question" />
                </td>
                <td v-if="columnClasses[proposalIndex].includes('proposal-col-last-day') && proposalIndex !== proposals.length - 1"
                    class="space" />
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SelectButton from './internal/SelectButton.vue'

export default {
  name: "ProposalTable",
  components: {SelectButton},
  props: {
    proposals: {type: Array, default: () => []},
    attendees: {type: Object, default: () => ({})},
    userName: {type: String, default: ''},
    userId: {type: Number, default: 0},
    disabled: {type: Boolean, default: () => false}
  },
  emits: ['proposalSelectionChanged'],
  computed: {
    userSelections() {
      return this.proposals.map(proposal => {
        if (!proposal || !proposal.votes) return 'none'
        if (proposal.votes.accept && proposal.votes.accept.selected) return 'accept'
        if (proposal.votes.maybe && proposal.votes.maybe.selected) return 'maybe'
        if (proposal.votes.reject && proposal.votes.reject.selected) return 'reject'
        return 'none'
      })
    },
    proposalDayInfos() {
      let days = []
      let currentDay = null
      let currentDayCount = 1
      for (let proposal of this.proposals) {
        if (proposal.start.toLocaleString('en-GB', {month: 'numeric', day: 'numeric'}) !== currentDay) {
          if (currentDay) days.push({day: currentDay, count: currentDayCount})
          currentDay = proposal.start.toLocaleString('en-GB', {month: 'numeric', day: 'numeric'})
          currentDayCount = 1
          continue
        }
        ++currentDayCount
      }
      if (currentDay) days.push({day: currentDay, count: currentDayCount})
      return days
    },
    columnClasses() {
      if (this.proposals.length === 0) return []
      let classes = []
      let lastDay = null
      for (let proposal of this.proposals) {
        if (proposal.start.toLocaleString('en-GB', {month: 'numeric', day: 'numeric'}) !== lastDay) {
          if (classes.length > 0) classes[classes.length - 1].push('proposal-col-last-day')
          lastDay = proposal.start.toLocaleString('en-GB', {month: 'numeric', day: 'numeric'})
          classes.push(['proposal-col-first-day'])
          continue
        }
        classes.push([])
      }
      classes[classes.length - 1].push('proposal-col-last-day')
      return classes
    },
    attendeeColumnClasses() {
      if (this.proposals.length === 0) return {}
      let result = {}
      for (let attendeeId in this.attendees) {
        // Make copy of the two level deep array
        let classes = this.columnClasses.map(classes => Array.from(classes))
        for (let index = 0; index < this.proposals.length; ++index) {
          if (this.isAccepted(this.proposals[index], attendeeId))
            classes[index].push('proposal-col-accepted');
          else if (this.isMaybe(this.proposals[index], attendeeId))
            classes[index].push('proposal-col-maybe');
          else if (this.isRejected(this.proposals[index], attendeeId))
            classes[index].push('proposal-col-rejected')
          else
            classes[index].push('proposal-col-unknown')
        }
        result[attendeeId] = classes
      }
      return result
    },
    userColumnClasses() {
      // Make copy of the two level deep array
      let classes = this.columnClasses.map(classes => Array.from(classes))
      for (let index = 0; index < this.proposals.length; ++index) {
        let proposal = this.proposals[index]
        if (!proposal || !proposal.votes)
          classes[index].push('proposal-col-unknown')
        else if (proposal.votes.accept && proposal.votes.accept.selected)
          classes[index].push('proposal-col-accepted');
        else if (proposal.votes.maybe && proposal.votes.maybe.selected)
          classes[index].push('proposal-col-maybe');
        else if (proposal.votes.reject && proposal.votes.reject.selected)
          classes[index].push('proposal-col-rejected')
        else
          classes[index].push('proposal-col-unknown')
      }
      return classes
    }
  },
  methods: {
    updateUserSelection(index, option) {
      if (!this.proposals[index]) {
        console.error('Proposal index ' + index + " is invalid!")
        return
      }
      let proposal = this.proposals[index]
      this.$emit('proposalSelectionChanged', {id: proposal.id, selection: option})
    },
    isAccepted(proposal, userId) {
      if (!proposal || !proposal.votes || !proposal.votes.accept || !proposal.votes.accept.users) return false
      return proposal.votes.accept.users.some(user => parseInt(user.id) === parseInt(userId))
    },
    isMaybe(proposal, userId) {
      if (!proposal || !proposal.votes || !proposal.votes.maybe || !proposal.votes.maybe.users) return false
      return proposal.votes.maybe.users.some(user => parseInt(user.id) === parseInt(userId))
    },
    isRejected(proposal, userId) {
      if (!proposal || !proposal.votes || !proposal.votes.reject || !proposal.votes.reject.users) return false
      return proposal.votes.reject.users.some(user => parseInt(user.id) === parseInt(userId))
    },
    getAcceptedCount(proposal) {
      let count = 0
      if (proposal.votes && proposal.votes.accept) {
        count = proposal.votes.accept.users.length || 0
        if (this.isAccepted(proposal, this.userId)) --count
        if (proposal.votes.accept.selected) ++count
      }
      return count
    },
    getMaybeCount(proposal) {
      let count = 0
      if (proposal.votes && proposal.votes.maybe) {
        count = proposal.votes.maybe.users.length || 0
        if (this.isMaybe(proposal, this.userId)) --count
        if (proposal.votes.maybe.selected) ++count
      }
      return count
    }
  }
}
</script>

<style lang="scss">
@import 'scss/variables';

.proposals {
  position: relative;

  > div {
    overflow-x: auto;
    margin-left: 10rem;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  tr td, tr th {
    height: 3rem;
    min-width: 3rem;
  }

  tr > :first-child {
    position: absolute;
    width: 10rem;
    left: 0;
    top: auto;
    text-overflow: ellipsis;
  }

  tbody tr:nth-child(n+2) > :first-child {
    border-right: .1rem solid var(--primary-color);
  }

  thead tr:first-child :not(:first-child) {
    background: var(--primary-color);
    color: var(--primary-color-text);
    border: .1rem solid var(--primary-color);
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    overflow: hidden;
    height: 2rem;
  }

  th {
    padding: .5rem;
    white-space: nowrap;
  }

  .space, tr.space td {
    background: inherit !important;
    border: none !important;
    min-width: .25rem;
    height: .1rem;
    padding: 0;
  }

  td:first-child {
    text-align: left;
    white-space: nowrap;
    padding: .5rem;
  }

  .proposal-col-first-day {
    border-left: .1rem solid var(--primary-color);
    border-spacing: .5rem;
  }

  .proposal-col-last-day {
    border-right: .1rem solid var(--primary-color);
  }

  tbody tr:first-child td, tbody tr:last-child td {
    border-bottom: .1rem solid var(--primary-color);
  }

  tbody tr:nth-child(n+3) td:first-child {
    border-left: .1rem solid var(--primary-color);
  }

  tbody tr:nth-child(3) td {
    border-top: .1rem solid var(--primary-color);
  }

  .proposal-col-accepted {
    background: $accept-color;
    color: $accept-text-color;
  }

  .proposal-col-maybe {
    background: $maybe-color;
    color: $maybe-text-color;
  }

  .proposal-col-rejected {
    background: $reject-color;
    color: $reject-text-color;
  }

  .proposal-col-unknown {
    background: $unknown-color;
    color: $unknown-text-color;
  }

  .p-selectbutton {
    height: 100%;
    width: 100%;

    .p-button.p-component {
      padding: 0;
      background: #ffffff44;
      border: none;
      display: flex;
      justify-content: center;
    }

    .p-button.p-component.highlight {
      background: none;
    }
  }
}
</style>