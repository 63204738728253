<template>
  <slot />
</template>

<script>
export default {
  name: "MultiStepPanel",
  props: {name: {type: String, default: '#STEP'}, completed: {type: Boolean, default: false}}
}
</script>
