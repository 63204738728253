<template>
  <div class="weekday-select">
    <span v-for="(name, i) in ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']"
          :key="'day-label-'+i"
          class="day-label">
      {{ name }}
    </span>
    <template v-for="(day, i) in days"
              :key="'day-'+i">
      <div>
        <div class="day-select"
             :class="{'day-select-disabled': day < firstDate || lastDate < day, 'day-select-active': inRange(day)}"
             @click="toggleDay(day)">
          <span>{{ day.getDate() }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  addDays,
  getNextSunday,
  inDateRange,
  isSameDay,
  toDateOnly
} from './calendar/calendar-helper'
import {parseISO8601Ranges, toISO8601} from './calendar/iso8601'

export default {
  name: "WeekdaySelect",
  props: {
    start: {type: Date, default: new Date()},
    end: {type: Date, default: addDays(new Date(), 14)},
    dateRange: {type: String, default: ''}
  },
  emits: ['update:dateRange'],
  computed: {
    firstDate() {
      return toDateOnly(this.start)
    },
    lastDate() {
      return toDateOnly(this.end)
    },
    days() {
      let first = new Date(this.firstDate)
      if (first.getDay() === 0) first.setDate(first.getDate() - 6)
      else first.setDate(first.getDate() - first.getDay() + 1)
      let last = getNextSunday(this.end)
      let days = []
      for (let day = first; day <= last; day.setDate(day.getDate() + 1)) days.push(new Date(day))
      return days
    }
  },
  methods: {
    inRange(day) {
      return inDateRange(day, this.dateRange)
    },
    toggleDay(day) {
      let dateRanges = parseISO8601Ranges(this.dateRange)
      // TODO Fix this has some weird results where range contains start/end with start > end
      if (this.inRange(day)) {
        // Remove
        let index = 0
        for (; index < dateRanges.length; ++index) {
          if (dateRanges[index].start <= day && day <= dateRanges[index].end) break
        }
        let range = dateRanges[index]
        if (isSameDay(range.start, day)) {
          range.start = addDays(range.start, 1)
          if (isSameDay(range.end, day)) dateRanges.splice(index, 1)
        } else if (isSameDay(range.end, day)) {
          range.end = addDays(range.end, -1)
        } else {
          let firstRange = {start: range.start, end: addDays(day, -1)}
          let secondRange = {start: addDays(day, 1), end: range.end}
          dateRanges.splice(index, 1, firstRange, secondRange)
        }
      } else {
        // Add
        let index = 0
        for (; index < dateRanges.length; ++index) {
          if (day < dateRanges[index].start) break
        }
        if (index < dateRanges.length && isSameDay(addDays(dateRanges[index].start, -1), day)) {
          dateRanges[index].start = day
        } else if (index > 0 && isSameDay(addDays(dateRanges[index - 1].end, 1), day)) {
          --index
          dateRanges[index].end = day
        } else {
          dateRanges.splice(index, 0, {start: day, end: day})
        }
        if (index > 0 && isSameDay(addDays(dateRanges[index - 1].end, 1), dateRanges[index].start)) {
          dateRanges.splice(index - 1, 2, {start: dateRanges[index - 1].start, end: dateRanges[index].end})
        }
      }
      this.$emit('update:dateRange', toISO8601(dateRanges, true))
    }
  }
}
</script>

<style lang="scss">
.weekday-select {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.4rem;
  text-align: center;

  .day-select {
    position: relative;
    display: inline-flex;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    background: #f6f6f6;

    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    span {
      align-self: center;
    }
  }

  .day-select-active {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }

  .day-select:hover:before {
    background: rgba(255, 255, 255, 0.2);
  }

  .day-select:active:before {
    background: rgba(0, 0, 0, 0.1);
  }

  .day-select-disabled {
    cursor: default;
    background: #fbfbfb;
    color: #aaaaaa;
  }

  .day-label {
    font-weight: bold;
  }
}
</style>