<template>
  <div ref="container"
       class="calendar-day"
       :class="{'disabled': disabled}">
    <div v-for="i in 48"
         :key="'separator-'+i"
         class="time-slot"
         :class="{'half-hour-slot': (i & 1) === 1}">
      <span v-if="i !== 1 && (i & 1) === 1"
            class="time-slot-time">
        {{ Math.floor((i - 1) / 2).toString().padStart(2, '0') }}:00
      </span>
    </div>
    <div class="calendar-day-content">
      <!-- User blocked column -->
      <div class="calendar-day-column blocked-column"
           :class="{'preview-active': createBlockedActive}"
           :title="disabled ? '' : 'Click and drag up and down to mark times where you are otherwise occupied.'">
        <div class="column-background blocked-background" />
        <div v-for="i in 96"
             :key="'user-blocked-button-'+i"
             class="user-timeslot"
             :style="{'top': timeSlotHeight / 2 * (i-1-0.5) + 'rem'}"
             @touchstart.prevent="evt => createEntryStart(i-1, evt, 'blocked')"
             @mousedown="evt => createEntryStart(i-1, evt, 'blocked')">
          <div class="user-entry-button blocked-background">
            +
          </div>
        </div>
        <BlockedEntry v-for="entry in userBlockedEntries"
                      :key="'user-blocked-'+entry.start+'/'+entry.end"
                      :start="entry.start"
                      :end="entry.end"
                      :entries="entry.entries"
                      :show-count="true" />
        <div ref="userBlockedPreview"
             class="user-entry-preview blocked-background" />
      </div>
      <!-- Other blocked column -->
      <div class="calendar-day-column blocked-column">
        <BlockedEntry v-for="entry in blockedEntries"
                      :key="'blocked-others-'+entry.start+'/'+entry.end"
                      :start="entry.start"
                      :end="entry.end"
                      :entries="entry.entries"
                      :show-count="true" />
      </div>
      <!-- Create proposal column -->
      <div class="calendar-day-column proposal-column"
           :class="{'preview-active': createProposalActive}"
           title="Click and drag up and down to create a proposal for a meeting time slot.">
        <div class="column-background proposal-background" />
        <div v-for="i in 96"
             :key="'user-proposal-button-'+i"
             class="user-timeslot"
             :style="{'top': timeSlotHeight / 2 * (i-1-0.5) + 'rem'}"
             @touchstart.prevent="evt => createEntryStart(i-1, evt, 'proposal')"
             @mousedown="evt => createEntryStart(i-1, evt, 'proposal')">
          <div class="user-entry-button proposal-background">
            +
          </div>
        </div>
        <div ref="userProposalPreview"
             class="user-entry-preview proposal-background" />
      </div>
      <!-- Proposals -->
      <div class="calendar-day-column flex-grow-1">
        <ProposalEntry v-for="entry in proposals"
                       :key="'proposal-'+entry.start+'-'+entry.end"
                       :start="entry.start"
                       :end="entry.end"
                       :entry="entry"
                       :disabled="disabled"
                       @delete="deleteEntry(entry)" />
      </div>
    </div>
    <BlockedDetails ref="calendarDetails"
                    :disabled="disabled"
                    @delete="deleteEntry" />
  </div>
</template>

<script>
import {ref} from 'vue'
import Style from '../scss/_export.module.scss'
import {addDays, toDateOnly} from '../calendar-helper'
import {groupEntries} from './group-helper'
import BlockedEntry from './BlockedEntry.vue'
import BlockedDetails from './BlockedDetails'
import ProposalEntry from './ProposalEntry'
import TouchHandler from './touch-handler'

export default {
  name: "CalendarDay",
  components: {BlockedEntry, BlockedDetails, ProposalEntry},
  inject: ['remToPx'],
  provide() {
    return {
      showCalendarDetails: this.showCalendarDetails
    }
  },
  props: {
    date: Date,
    entries: {type: Array, default: () => []},
    createEntryTemplate: {type: Function, required: true},
    disabled: Boolean
  },
  emits: ['update:entries'],
  setup() {
    let createEntryHandler = ref(new TouchHandler())
    return {createEntryHandler}
  },
  data: () => ({
    timeSlotHeight: parseFloat(Style.timeslotheight),
    createBlockedActive: false,
    createProposalActive: false,
    createEntryType: '',
    createEntryStartSlot: 0,
    createEntryEndSlot: 0,
    entryPreviewElement: null
  }),
  computed: {
    currentDay() {
      return toDateOnly(this.date)
    },
    nextDay() {
      return addDays(this.currentDay, 1)
    },
    dayEntries() {
      return this.entries.filter(entry => entry.start < this.nextDay && entry.end > this.currentDay)
    },
    userEntries() {
      return this.dayEntries.filter(entry => !entry.readonly)
    },
    otherEntries() {
      return this.dayEntries.filter(entry => entry.readonly)
    },
    userBlockedEntries() {
      let userBlocked = this.userEntries.filter(entry => entry.type === 'blocked')
      return groupEntries(userBlocked)
    },
    blockedEntries() {
      let otherBlocked = this.otherEntries.filter(entry => entry.type === 'blocked')
      return groupEntries(otherBlocked)
    },
    proposals() {
      return this.dayEntries.filter(entry => entry.type === 'proposal')
    }
  },
  mounted() {
    this.createEntryHandler.onmove = this.createEntryMove.bind(this)
    this.createEntryHandler.oncancel = this.createEntryCancel.bind(this)
    this.createEntryHandler.onend = this.createEntryEnd.bind(this)
  },
  methods: {
    showCalendarDetails(el, details) {
      this.$refs.calendarDetails.show(el, details)
    },
    deleteEntry(entry) {
      let entries = this.entries
      for (let i = 0; i < entries.length; ++i) {
        if (entry !== entries[i]) continue
        entries.splice(i, 1)
        break
      }
      if (entries.length === this.entries.length) return
      this.$emit('update:entries', entries)
    },
    getPos(evt) {
      let container = this.$refs.container
      let rect = container.getBoundingClientRect()
      return {x: evt.clientX + container.scrollLeft - rect.left, y: evt.clientY + container.scrollTop - rect.top,}
    },
    getSlotIndex(y) {
      return Math.round(y * 2 / this.remToPx(this.timeSlotHeight))
    },
    updatePreview(start, end) {
      if (end < start) {
        this.updatePreview(end, start)
        return
      }
      this.entryPreviewElement.style.top = start / 2 * this.timeSlotHeight + 'rem'
      this.entryPreviewElement.style.height = (end - start) / 2 * this.timeSlotHeight + 'rem'
    },
    createEntryStart(slotIndex, evt, type) {
      if (type === 'proposal') {
        this.entryPreviewElement = this.$refs.userProposalPreview
        this.createProposalActive = true
      } else if (type === 'blocked') {
        this.entryPreviewElement = this.$refs.userBlockedPreview
        this.createBlockedActive = true
      } else {
        return
      }
      this.createEntryType = type
      this.createEntryStartSlot = slotIndex
      this.createEntryHandler.start(evt)
      this.updatePreview(slotIndex, slotIndex)
    },
    createEntryMove(evt) {
      let pos = this.getPos(evt)
      this.createEntryEndSlot = this.getSlotIndex(pos.y)
      this.updatePreview(this.createEntryStartSlot, this.createEntryEndSlot)
    },
    createEntryCancel() {
      this.createBlockedActive = false
      this.createProposalActive = false
    },
    createEntryEnd(evt) {
      if (evt) {
        let pos = this.getPos(evt)
        this.createEntryEndSlot = this.getSlotIndex(pos.y)
      }
      if (this.createEntryStartSlot !== this.createEntryEndSlot) {
        let start = new Date(this.currentDay)
        let end = new Date(this.currentDay)
        start.setMinutes(this.createEntryStartSlot * 15)
        end.setMinutes(this.createEntryEndSlot * 15)
        this.createEntry(start, end)
      }
      this.createBlockedActive = false
      this.createProposalActive = false
    },
    createEntry(start, end) {
      if (end < start) {
        this.createEntry(end, start)
        return
      }
      let entries = Array.from(this.entries)
      let template = this.createEntryTemplate(this.createEntryType)
      entries.push({...template, start: start, end: end, type: this.createEntryType})
      this.$emit('update:entries', entries)
    }
  }
}
</script>

<style lang="scss">
@use "sass:math";
@import "../scss/variables";

.flex-grow-1 {
  flex-grow: 1;
}

.calendar-day.disabled {
  .user-timeslot {
    display: none;
  }

  .calendar-day-column .column-background {
    display: none;
  }

  .calendar-day-column.proposal-column {
    display: none;
  }
}

.calendar-day {
  flex-grow: 1;
  position: relative;
  width: 8rem;
  border-left: 1px solid #888888;
  border-right: 1px solid #888888;

  .time-slot {
    display: flex;
    height: $time-slot-height;
    border-bottom: 2px solid #dddddd;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .time-slot.half-hour-slot {
    border-bottom-color: #eeeeee;
    border-bottom-width: 1px;
  }

  .time-slot-time {
    color: #aaaaaa;
    background: #ffffff;
    padding: 0 0.25rem;
    margin-top: -0.5rem;
    line-height: 1rem;
    user-select: none;
  }

  .calendar-day-content {
    position: absolute;
    left: 1.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;

    .calendar-day-column {
      position: relative;
      flex-basis: 2rem;
    }
  }

  .user-timeslot {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: #{math.div($time-slot-height, 2)};
    cursor: pointer;
  }

  .user-entry-button {
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 50%;
    user-select: none;
    opacity: 0;
  }

  .user-timeslot:hover .user-entry-button {
    opacity: 100%;
  }

  .column-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.5rem;
    right: 0.5rem;
    opacity: 0.1;
  }

  .blocked-background {
    background-color: $blocked-color;
    color: $blocked-text-color;
  }

  .proposal-background {
    background-color: $proposal-color;
    color: $proposal-text-color;
  }

  .user-entry-preview {
    display: none;
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    min-height: 1rem;
    border-radius: $border-radius;
    pointer-events: none;
  }

  .calendar-day-column:hover .column-background {
    opacity: 0.3;
  }

  .calendar-day-column.preview-active {
    .column-background {
      opacity: 0.3;
    }

    .user-timeslot .user-entry-button {
      opacity: 0;
    }

    .user-entry-preview {
      display: block;
    }
  }
}

</style>