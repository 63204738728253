<template>
  <template v-if="loaded">
    <template v-if="!error">
      <Message v-if="closed"
               class="m-4"
               severity="warn"
               :closable="false"
               :sticky="true">
        This meeting is already closed.
      </Message>
      <Message v-else-if="deadline && deadline < now"
               class="m-4"
               severity="info"
               :closable="false"
               :sticky="true">
        The deadline for this meeting has passed. Your changes may not be noticed.
      </Message>
      <div class="p-fluid grid  grid-nogutter text-left ml-1 md:ml-2 mr-1 md:mr-2">
        <div class="col-12 grid grid-nogutter"
             :class="{'md:col-6': isOrganizer}">
          <div class="col-12">
            <p v-if="isOrganizer">
              This is your meeting regarding: <span class="font-bold">{{ subject }}</span>
            </p>
            <p v-else>
              <span class="font-bold">{{ organizer }}</span> invited you to schedule a meeting regarding:
              <span class="font-bold">{{ subject }}</span><br>
              You can participate by marking time blocks where you are unavailable as blocked (blue), voting on
              proposals, or creating new proposals (pink).
            </p>
            <template v-if="deadline">
              <p>
                Please participate before:
                <Chip :label="deadline.toLocaleString('en-GB', {dateStyle: 'medium', timeStyle: 'short'})" />
              </p>
            </template>
          </div>
          <div class="grid p-fluid col-12 mt-1">
            <div class="field col-12 lg:col-6">
              <span class="p-float-label">
                <InputText id="name"
                           v-model="attendeeName"
                           :disabled="closed" />
                <label for="name">Name</label>
              </span>
            </div>
            <div class="field col-12 lg:col-6">
              <span class="p-float-label">
                <InputText id="email"
                           v-model="attendeeEmail"
                           class="p-fluid"
                           :disabled="closed" />
                <label for="email">E-Mail</label>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6 mb-1">
          <Button icon="pi pi-users"
                  class="p-button-text"
                  style="width:auto"
                  :label="attendeesSummaryLabel"
                  @click="showAttendees=true" />
          <div v-if="isOrganizer" class="col-12">
            <div class="field-checkbox">
              <Checkbox id="openpoll"
                        v-model="open"
                        :binary="true"
                        :disabled="closed" />
              <label for="openpoll">Open for additional attendees</label>
            </div>
            <small v-show="meeting.open !== open">You need to update the meeting for this change to take effect.</small>
          </div>
          <div v-show="open"
               class="field col-12">
            <label for="open-poll-link">Open Poll Link:</label>
            <div class="p-inputgroup">
              <input-text id="open-poll-link"
                          ref="openPollInput"
                          type="text"
                          class="p-input input p-component"
                          :value="openPollLink"
                          readonly="true" />
              <Button class="pi pi-copy"
                      @click="() => copyLink($refs['openPollInput'].$el)" />
            </div>
          </div>
        </div>
      </div>
      <TabView>
        <TabPanel header="Calendar">
          <ProposalCalendar ref="calendar"
                            v-model:entries="entries"
                            class="m-0 md:ml-2 md:mr-2 mb-2"
                            :date-range="dateRange"
                            :unavailable-counts="unavailableCounts"
                            :disabled="closed"
                            :create-entry-template="createEntryTemplate"
                            :user-id="attendeeId"
                            @import="importDialog = true"
                            @import-success="$toast.add({severity: 'info', summary: 'Calendar imported!',
                                                         detail: 'The file(s) were imported successfully!', life: 3000})"
                            @import-fail="$toast.add({severity: 'error', summary: 'Unexpected error!',
                                                      detail: $event, life: 5000})" />
        </TabPanel>
        <TabPanel header="Proposals">
          <ProposalTable class="mt-2"
                         :proposals="proposals"
                         :attendees="attendees"
                         :user-id="attendeeId"
                         :user-name="attendeeName"
                         :disabled="closed"
                         @proposal-selection-changed="updateProposalSelection($event)" />
        </TabPanel>
      </TabView>
      <div class="flex justify-content-end">
        <Button :label="meeting.participated ? 'Update' : 'Participate'"
                :disabled="!readyToUpdate"
                class="mt-2 mr-1 md:mr-2 mb-2"
                @click="update" />
      </div>
    </template>
    <template v-else>
      <Message class="m-4"
               severity="error"
               :closable="false"
               :sticky="true">
        <h4>{{ error }}</h4>
      </Message>
    </template>
  </template>
  <div v-else
       class="fill-height">
    <ProgressSpinner />
    <h4>Loading meeting</h4>
  </div>
  <Dialog v-model:visible="showAttendees"
          :modal="true"
          :dismissable-mask="true"
          :breakpoints="{'960px': '75vw', '640px': '100vw'}"
          :style="{width: '50vw'}"
          :header="attendeesSummaryLabel">
    <table class="attendee-table">
      <thead>
        <tr>
          <th />
          <th>Attendee</th>
          <th>Participated</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(att, attId) in attendees"
                  :key="'attendee-info-' + attId">
          <tr>
            <td>
              <Button v-if="isOrganizer"
                      :icon="isOpen[attId] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                      class="p-button-rounded  p-button-text"
                      @click="() => toggleOpen(id)" />
            </td>
            <td>{{ att.name }}</td>
            <td class="participated-column">
              <i v-if="att.participated" class="pi pi-check-circle" />
              <i v-else class="pi pi-question" />
            </td>
          </tr>
          <tr v-show="isOrganizer && isOpen[attId]">
            <td />
            <td colspan="3">
              <div class="p-inputgroup">
                <input-text :ref="el => { if (el) inputs[attId] = el }"
                            type="text"
                            class="p-input p-component"
                            :value="getLinkForAttendee(attId, att.key)"
                            readonly="true" />
                <Button class="pi pi-copy" @click="() => copyLinkAt(attId)" />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </Dialog>
</template>

<script>
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Message from 'primevue/message'
import ProgressSpinner from 'primevue/progressspinner'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import ProposalCalendar from '../components/calendar/ProposalCalendar.vue'
import ProposalTable from '../components/calendar/ProposalTable.vue'
import {getMeeting, getMeetingPollLink, updateMeeting} from '@/api/openmeet-api'

export default {
  name: "MeetingPage",
  components: {
    ProposalCalendar,
    Checkbox,
    Chip,
    Dialog,
    InputText,
    Message,
    ProgressSpinner,
    ProposalTable,
    TabPanel,
    TabView
  },
  provide() {
    return {getAttendee: this.getAttendee}
  },
  data: () => ({
    loaded: false,
    error: null,
    id: null,
    attendee: null,
    key: null,
    meeting: null,
    isOrganizer: false,
    closed: false,
    deadline: null,
    subject: '',
    organizer: null,
    attendeeName: '',
    attendeeId: -1,
    attendeeEmail: '',
    dateRange: '',
    entries: [],
    unavailableCounts: [],
    attendees: null,
    open: false,
    openId: '',
    openKey: '',
    isOpen: {},
    inputs: {},
    now: new Date(),
    nowInterval: null,

    importDialog: false,
    showAttendees: false
  }),
  computed: {
    openPollLink() {
      return getMeetingPollLink(this.id, this.openId, this.openKey)
    },
    readyToUpdate() {
      return !this.closed &&
          (this.meeting.userName || this.meeting.userEmail || this.attendeeName.length >= 2) &&
          (this.attendeeEmail.length === 0 || this.attendeeEmail.indexOf('@') !== -1)
    },
    proposals() {
      return this.entries.filter(entry => entry.type === 'proposal')
    },
    attendeesSummaryLabel() {
      if (!this.attendees) return "Loading..."
      let result = Object.keys(this.attendees).length + ' Attendees participated'
      if (this.isOrganizer && !this.open) {
        let countParticipated = Object.keys(this.attendees).reduce((count, key) => count + (this.attendees[key].participated ? 1 : 0), 0)
        return countParticipated + ' out of ' + result
      }
      return result
    }
  },
  watch: {
    $route(to, from) {
      if (to === from) return
      this.loadMeeting()
    }
  },
  mounted() {
    this.loadMeeting()
    this.nowInterval = setInterval(function () {
      this.now = new Date()
    }, 5000)
  },
  unmounted() {
    if (this.nowInterval) clearInterval(this.nowInterval)
  },
  methods: {
    getAttendee(id) {
      return this.meeting.attendees[id]
    },
    createEntryTemplate() {
      return {readonly: false}
    },
    getLinkForAttendee(id, key) {
      return getMeetingPollLink(this.id, id, key)
    },
    copyLink(input) {
      input.select()
      document.execCommand('copy')
      this.$toast.add({severity: 'info', summary: 'Copied!', detail: 'Link copied to clipboard.', life: 3000})
    },
    copyLinkAt(id) {
      let input = this.inputs[id].$el
      if (!input) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Copying failed!',
          detail: 'Perhaps you need to update your browser.',
          life: 3000
        })
        return
      }
      this.copyLink(input)
    },
    toggleOpen(id) {
      this.isOpen[id] = !this.isOpen[id]
    },
    loadMeeting() {
      let changed = !this.loaded
      if (this.$route.query.id !== this.id) changed = true
      if (this.$route.query.attendee !== this.attendee) changed = true
      if (this.$route.query.key !== this.key) changed = true
      if (!changed) return

      this.id = this.$route.query.id
      this.attendee = this.$route.query.attendee
      this.key = this.$route.query.key
      this.loaded = false
      if (!this.id || !this.attendee || !this.key) {
        this.error = "Invalid url!"
        this.loaded = true
        return
      }
      return getMeeting(this.id, this.attendee, this.key).then(meeting => {
        this.initFromMeeting(meeting)
        this.loaded = true
      }).catch(result => {
        if (result.response && result.response.status === 403)
          this.error = "Unauthorized Access!"
        else if (result.message)
          this.error = "Failed to retrieve meeting: " + result.message + "!"
        else
          this.error = "Failed to retrieve meeting!"
        this.loaded = true
      })
    },
    initFromMeeting(meeting) {
      this.meeting = meeting
      this.subject = meeting.subject
      this.organizer = meeting.organizer
      this.isOrganizer = !!meeting.openId
      this.attendeeId = meeting.userId
      this.attendeeName = meeting.userName || ''
      for (let id in meeting.attendees) { // TODO: Why?
        if (meeting.attendees[id].name !== this.attendeeName) continue
        this.attendeeId = parseInt(id)
        break
      }
      this.attendeeEmail = meeting.userEmail || ''
      this.dateRange = meeting.dateRange
      let entries = []
      for (let entry of meeting.entries) {
        entries.push({type: 'blocked', ...entry, readonly: true})
      }
      for (let proposal of meeting.proposals) {
        entries.push({...proposal, type: 'proposal', readonly: true})
      }
      for (let entry of meeting.userEntries) {
        entries.push({type: 'blocked', ...entry})
      }
      for (let proposal of meeting.userProposals) {
        entries.push({...proposal, type: 'proposal'})
      }
      this.entries = entries
      this.unavailableCounts = meeting.unavailableCounts
      this.attendees = meeting.attendees
      this.open = meeting.open
      this.openId = meeting.openId
      this.openKey = meeting.openKey
      this.closed = meeting.closed || false
      this.deadline = meeting.deadline
    },
    update() {
      let entriesByUser = this.entries.filter(e => !e.readonly && e.type !== 'proposal')
      let proposals = this.entries.filter(e => e.type === 'proposal')
      let name = this.attendeeName === this.meeting.userName ? undefined : this.attendeeName
      let email = this.attendeeEmail === this.meeting.userEmail ? undefined : this.attendeeEmail
      let open = this.open === this.meeting.open ? undefined : this.open
      updateMeeting({
        id: this.id,
        attendee: this.attendee,
        key: this.key,
        calendarEntries: entriesByUser,
        name, email,
        proposals,
        open
      }).then(result => {
        if (result.attendeeId) {
          this.attendee = result.attendeeId
          this.key = result.attendeeKey
          this.$router.replace({
            path: this.$router.currentRoute.value.path,
            query: {...this.$router.currentRoute.value.query, attendee: result.attendeeId, key: result.attendeeKey}
          })
        }
        this.initFromMeeting(result)
        this.$toast.add({severity: 'success', summary: 'Meeting updated!', life: 5000})
      }).catch(result => {
        let title = result && result.response && result.response.status === 403 && "Unauthorized" || "Error"
        let message = result.response.status === 500 && result.response.data !== 'Internal Server Error' && result.response.data || 'Failed to update meeting!'
        this.$toast.add({
          severity: 'error',
          summary: title,
          detail: message,
          life: 5000
        })
      })
    },
    updateProposalSelection(event) {
      for (let entry of this.entries) {
        if (entry.type !== 'proposal') continue
        if (entry.id !== event.id) continue

        if (!entry.votes) entry.votes = {}
        if (!entry.votes.accept) entry.votes.accept = {}
        if (!entry.votes.maybe) entry.votes.maybe = {}
        if (!entry.votes.reject) entry.votes.reject = {}
        if (event.selection === 'accept') {
          entry.votes.accept.selected = true
          entry.votes.maybe.selected = false
          entry.votes.reject.selected = false
        } else if (event.selection === 'maybe') {
          entry.votes.accept.selected = false
          entry.votes.maybe.selected = true
          entry.votes.reject.selected = false
        } else if (event.selection === 'reject') {
          entry.votes.accept.selected = false
          entry.votes.maybe.selected = false
          entry.votes.reject.selected = true
        } else if (event.selection === null) {
          entry.votes.accept.selected = false
          entry.votes.maybe.selected = false
          entry.votes.reject.selected = false
        } else {
          console.error('Invalid proposal selection:', event.selection)
        }
        return
      }
      console.error('Could not find proposal entry with id: ' + event.id)
    }
  }
}
</script>

<style lang="scss">

#app > .tabview > .tabview-panels {
  padding: 0;
}

.attendee-table {
  .participated-column {
    text-align: center;

    i.pi-check-circle {
      color: var(--primary-color);
    }

    i.pi-question {
      padding: 0.25rem;
      border-radius: 50%;
      background-color: var(--yellow-600);
      font-weight: bold;
      color: white;
    }
  }
}

</style>